import React, { useCallback, useState, Children } from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { withRouter } from "react-router";
import base from "../../config/FbConfig";
import "firebase/firestore";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  /*  input: {
    display: "none",
  }, */
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const QuesPor = ({ history }) => {
  const classes = useStyles();
  const [category, setCategory] = React.useState("");
  const [image, setImage] = React.useState("");
  const [url, setUrl] = React.useState("");
  const handleChange = (event) => {
    setCategory(event.target.value);
  };
  const [ques, setState] = useState({
    eventName: "",
    postName: "",
    title: "",
    time: "",
    info: "",
  });

  const updateField = (e) => {
    e.preventDefault();
    setState({
      ...ques,
      [e.target.name]: e.target.value,
    });
  };
  const handleImage = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };
  const handleUpload = () => {
    const uploadTask = base.storage().ref(`images/${ques.postName}`).put(image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        console.log("snapshot");
      },
      (error) => {
        console.log(error);
      },
      () => {
        base
          .storage()
          .ref("images")
          .child(ques.postName)
          .getDownloadURL()
          .then((url) => {
            setUrl(url);
          });
      }
    );
  };
  console.log(ques);
  const db = base.firestore();
  const handleData = (event) => {
    event.preventDefault();
    const { category, eventName, time, info } = event.target.elements;

    return db
      .collection("Events")
      .doc(category.value)
      .collection("Event")
      .doc(eventName.value)
      .set({
        img: url,
        title: eventName.value,
        time: time.value,
        info: info.value,
      })
      .then(() => {
        alert("Post Added to database, please refresh the browser");
      })
      .then()
      .catch((error) => {
        //console.error("Error adding document: ", error);
      });
  };
  //console.log(ques);

  return (
    <Container maxWidth="md" style={{ padding: "3vh" }}>
      <Paper style={{ padding: "2vh" }}>
        <form onSubmit={handleData} validate id="myForm">
          <Typography variant="h6" style={{ margin: "1vh" }}>
            Event Category :
          </Typography>
          <FormControl
            fullWidth
            variant="outlined"
            className={classes.formControl}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Category
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              name="category"
              value={category}
              onChange={handleChange}
              label="category"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"UpComming Events"}>UpComming Events</MenuItem>
              <MenuItem value={"Present Events"}>Present Events</MenuItem>
              <MenuItem value={"Past Events"}>Past Events</MenuItem>
            </Select>
          </FormControl>
          <Typography variant="h6" style={{ margin: "1vh" }}>
            Event Name :
          </Typography>
          <TextField
            id="outlined-textarea"
            fullWidth
            name="eventName"
            label="Event Name"
            placeholder="enter Event Name here"
            multiline
            value={ques.eventName}
            onChange={updateField}
            variant="outlined"
          />
          <Typography variant="h6" style={{ margin: "1vh" }}>
            Post Name :
          </Typography>
          <TextField
            id="outlined-textarea"
            fullWidth
            name="postName"
            label="Post Name"
            placeholder="enter Post Name here"
            multiline
            value={ques.posttName}
            onChange={updateField}
            variant="outlined"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6" style={{ margin: "1vh" }}>
              image :
            </Typography>
            <input
              style={{ margin: "2.7vh" }}
              accept="image/*"
              name="img"
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleImage}
            />
            <Button
              variant="outlined"
              color="primary"
              onClick={handleUpload}
              style={{ margin: "1vh" }}
            >
              Upload Image
            </Button>
            <img src={url} height="250" width="250" />
          </div>
          <Typography variant="h6" style={{ margin: "1vh" }}>
            Time :
          </Typography>
          <TextField
            style={{ marginTop: "1vh" }}
            id="standard-textarea"
            fullWidth
            name="time"
            label="Time"
            placeholder="example: 11th March 2000"
            multiline
            value={ques.time}
            onChange={updateField}
            variant="outlined"
          />
          <Typography variant="h6" style={{ margin: "1vh" }}>
            InFo:
          </Typography>
          <TextField
            style={{ marginTop: "2vh" }}
            id="standard-textarea"
            fullWidth
            name="info"
            label="InFo"
            placeholder="enter information about the event in 2 to 3 lines"
            multiline
            value={ques.info}
            onChange={updateField}
            variant="outlined"
          />
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            color="primary"
            className={classes.submit}
          >
            Add Post
          </Button>
        </form>
      </Paper>
    </Container>
  );
};
export default withRouter(QuesPor);
