import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import awarestat from "../img/awarestat.jpeg";
import cloudstat from "../img/cloudstat.jpeg";
import codestat from "../img/codestat.jpeg";
import hackstat from "../img/hackstat.jpeg";
import holostat from "../img/holostat.jpeg";
import kalastat from "../img/kalastat.jpeg";
import ph from "./Events/s2.jpeg"
import ph1 from "./img/statp.jpeg"
import nari1 from "../img/nari1.jpeg";
import nari2 from "../img/nari2.jpeg";
import nari3 from "../img/nari3.jpeg";
import nari4 from "../img/nari4.jpeg";
import fresh12 from "../img/fresh2.jpeg"
import fresh13 from "../img/fresh4.jpeg"
import vv1 from "../img/vv1.jpeg"
import vv2 from "../img/vv2.jpeg"
import vv3 from "../img/vv3.jpeg"
import toy from "../img/toycathon.jpeg"
import samskar from "../img/e-nnovate.jpeg"
import agro from "../img/vaag post 1.jpg"

export default function EventsSlider() {
  const settings = {
    pauseOnHover: false,
    centerMode: true,
    centerPadding: "90px",
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2100,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "10px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0",
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
        <div>
        <div
          style={{
            margin: "5vh",
            height: "54vh",
            maxWidth: "100%",
            backgroundImage: `url(${agro})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></div>
      </div>
      <div>
        <div
          style={{
            margin: "5vh",
            height: "54vh",
            maxWidth: "100%",
            backgroundImage: `url(${toy})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></div>
      </div>
      
      <div>
        <div
          style={{
            margin: "5vh",
            height: "54vh",
            maxWidth: "100%",
            backgroundImage: `url(${samskar})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></div>
      </div>

      <div>
        <div
          style={{
            margin: "5vh",
            height: "54vh",
            maxWidth: "100%",
            backgroundImage: `url(${vv1})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></div>
      </div>

      <div>
        <div
          style={{
            margin: "5vh",
            height: "54vh",
            maxWidth: "100%",
            backgroundImage: `url(${vv2})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></div>
      </div>
      <div>
        <div
          style={{
            margin: "5vh",
            height: "54vh",
            maxWidth: "100%",
            backgroundImage: `url(${vv3})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></div>
      </div>
         <div>
        <div
          style={{
            margin: "5vh",
            height: "54vh",
            maxWidth: "100%",
            backgroundImage: `url(${fresh12})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></div>
      </div>
      <div>
        <div
          style={{
            margin: "5vh",
            height: "54vh",
            maxWidth: "100%",
            backgroundImage: `url(${fresh13})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></div>
      </div>
 
         <div>
        <div
          style={{
            margin: "5vh",
            height: "54vh",
            maxWidth: "100%",
            backgroundImage: `url(${ph1})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></div>
      </div>
       <div>
        <div
          style={{
            margin: "5vh",
            height: "54vh",
            maxWidth: "100%",
            backgroundImage: `url(${ph})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></div>
      </div>
      <div>
        <div
          style={{
            margin: "5vh",
            height: "54vh",
            maxWidth: "100%",
            backgroundImage: `url(${holostat})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></div>
      </div>

      <div>
        <div
          style={{
            margin: "5vh",
            height: "54vh",
            maxWidth: "100%",
            backgroundImage: `url(${cloudstat})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></div>
      </div>
      <div>
        <div
          style={{
            margin: "5vh",
            height: "54vh",
            maxWidth: "100%",
            backgroundImage: `url(${hackstat})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></div>
      </div>
      <div>
        <div
          style={{
            margin: "5vh",
            height: "54vh",
            maxWidth: "100%",
            backgroundImage: `url(${codestat})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></div>
      </div>
      <div>
        <div
          style={{
            margin: "5vh",
            height: "54vh",
            maxWidth: "100%",
            backgroundImage: `url(${awarestat})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></div>
      </div>
      <div>
        <div
          style={{
            margin: "5vh",
            height: "54vh",
            maxWidth: "100%",
            backgroundImage: `url(${kalastat})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></div>
      </div>
      <div>
        <div
          style={{
            margin: "5vh",
            height: "54vh",
            maxWidth: "100%",
            backgroundImage: `url(${nari4})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></div>
      </div>
      <div>
        <div
          style={{
            margin: "5vh",
            height: "54vh",
            maxWidth: "100%",
            backgroundImage: `url(${nari1})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></div>
      </div>
      <div>
        <div
          style={{
            margin: "5vh",
            height: "54vh",
            maxWidth: "100%",
            backgroundImage: `url(${nari2})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></div>
      </div>
      <div>
        <div
          style={{
            margin: "5vh",
            height: "54vh",
            maxWidth: "100%",
            backgroundImage: `url(${nari3})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></div>
      </div>
    </Slider>
  );
}
