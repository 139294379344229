import React, { useEffect, useState } from "react";
import base from "./FbConfig";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [used, setState] = useState({
    userName: null,
    currentUser: null,
    photo: null,
  });

  useEffect(() => {
    const unsubscribe = base.auth().onAuthStateChanged(function (user) {
      if (user) {
        setState({
          ...used,
          userName: user.displayName,
          currentUser: user.uid,
          photo: user.photoURL,
        });
      } else {
        setState({
          ...used,
          userName: null,
          currentUser: null,
          photo: null,
        });
      }
    });
    return () => unsubscribe();
  }, []);
  // console.log(currentUser);
  return (
    <AuthContext.Provider
      value={{
        userId: used.currentUser,
        userName: used.userName,
        photo: used.photo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
