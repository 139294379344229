import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import "./Comps.css";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

export default function Top(props) {
  return (
    <div className="center">
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="flex">
        <Grid item xs={12} sm={12} md={7} lg={7} xl={6} className="imglogo">
          <img src={props.imager} alt="logo" />
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={6} className="captions">
          <Typography className="caption">
            <h1 className="textAb">{props.cap1}</h1>
          </Typography>
          <Typography className="caption">
            <h1 className="textAb">{props.cap2}</h1>
          </Typography>
          <Typography className="caption">
            <h1 className="textAb">{props.cap3}</h1>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "15vh",
          }}
        >
          <ThemeProvider theme={theme}>
            <Typography
              variant="subtitle1"
              align="center"
              style={{
                fontWeight: "bold",
                letterSpacing: "2.1px",
                fontFamily: "Montserrat, sans-serif",
                color: "#000",
              }}
            >
              We Build
            </Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <Typography
              variant="h5"
              align="center"
              style={{
                fontWeight: "bold",
                letterSpacing: "4.5px",
                fontFamily: "Montserrat, sans-serif",
                color: "#000",
                padding: "0vh 3vh 2vh 3vh",
              }}
            >
              Startups, Entrepreneurs, Tech-Experts
            </Typography>
          </ThemeProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="middle">
          <ThemeProvider theme={theme}>
            <Typography
              variant="h3"
              align="left"
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
                color: "#fff",
                textTransform: "uppercase",
                wordSpacing: "2.1px",
              }}
            >
              {props.caption}
            </Typography>
          </ThemeProvider>
        </Grid>
      </Grid>
    </div>
  );
}
