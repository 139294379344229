import React from "react";
import { Grid, Typography, Paper, Container } from "@material-ui/core";
import "./pages.css";
import "../Comps/Comps.css";
import img4 from "./img/O.PNG";
import img5 from "./img/e.png";
import img6 from "./img/SF.png";
import img7 from "./img/SS.png";
import img8 from "./img/DRNME.png";
import img9 from "./img/BV.png";
import img10 from "./img/sp.png";
import img11 from "./img/bt.png";
import Top from "../Comps/Top";
import MiddleT from "../Comps/MiddleT";
import mission from "./about/Mission.png";
import vibaA from "./about/vibaA.png";
import backed1 from "./about/backed1.png";
import ddd from "./about/ddd.png";
import vision from "./about/vision.png";
import mentor from "./about/mentor.png";
import seed from "./about/seed.png";
import work from "./about/workspace.png";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

export default function About() {
  const miss = [
    {
      name: "VISION",
      img: vision,
      flex: "flex-end",
      data:
        "'A Ship in the harbor is safe, but that is not what it is built for'. We as VIBA aim to provide a platform for students, to bring out fearless, pioneering and indigenous entrepreneurs. We give everything it takes for an indigenous and fearless entrepreneur.",
    },
    {
      name: "MISSION",
      img: mission,
      flex: "flex-start",
      data:
        "VIBA has always been in a mission mode to be an effective assemblage with industries to foster, promote, grow and encourage commercialization leading to the creation of social values and wealth through successful ventures",
    },
  ];
  const serve = [
    {
      name: "SEED FUND",
      img: seed,
      flex: "flex-end",
      data:
        "Best ideation is a binge expectation form a student to VIBA. A seed fund is offered through our associated companies to move forward and shoot up in the market.",
    },
    {
      name: "WORKSPACE",
      img: work,
      flex: "center",
      data:
        "A work worthy ambience is an assured factor on VIBA's floor. The spirt of enthusiasm and participative tinge can be the added wholesome experience felt.",
    },
    {
      name: "MENTORSHIP",
      img: mentor,
      flex: "flex-start",
      data:
        "The finest mentoring panel is onboard in VIBA. They reinforce and push you forward in every possible aspect and avail their assistance throughout whenever required.",
    },
  ];
  const aches = [
    {
      name: "Anvesh Reddy",
      ache: "Selected for a paid internship in Dhruva space.",
    },
    {
      name: "Onisha Reddy",
      ache: "She has presented her start-up in Bio Asia.",
    },
    {
      name: "Our Collaborations",
      ache: "Moving into collaboration with some start-ups.",
    },

    {
      name: "Krushi Seva",
      ache:
        "Our start-up Krushiseva made selection into finals of Pitch Fest-2020.",
    },
    {
      name: "Avasyu Tech",
      ache:
        " Our start-up Avasyutech made selection into finals of Pitch Fest-2020.",
    },
    {
      name: "Team Invincible",
      ache:
        "Team Invincible secured second place in 'Hack for the Need' online hackathon",
    },
    {
      name: "Krushi Seva",
      ache:
        " Our team Krushiseva had place in top-100 selections, in MHRD-SAMADHAN event",
    },
    {
      name: "Hamaara Suraksha",
      ache:
        "Our team Hamaara Suraksha had place in top-60 selections, in MHRD-SAMADHAN event.",
    },
    {
      name: "Avasyu Tech",
      ache:
        "ATAL INCUBATION CENTER,NITTE has on boarded VIBA Startup AvasyuTech",
    },
    {
      name: "Olokart",
      ache:
        "ATAL INCUBATION CENTER,NITTE has on boarded VIBA Startup Olokart",
    },
    {
      name: "Incubation Patner",
      ache:
        "Viba pronounced as incubation partner for NASA SPACE APPS CHALLENGE 2020",
    },
    {
      name: "Olokart",
      ache:
        "Our Startup Olokart selected for MEITY(Ministry of Electronics and Information Technology) Startup hub program powered by Padup Ventures",
    },
    {
      name: "LAALM",
      ache:
        "Our Startup LAALM selected for MEITY(Ministry of Electronics and Information Technology) Startup hub program powered by Padup Ventures",
    },
    
  ];
  const part = [img4, img5, img11, img6, img7, img8, img9, img10];
  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="cont"
        style={{ backgroundColor: "#FFF" }}
      >
        <Top
          imager={vibaA}
          caption={
            "Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work. And the only way to do great work is to love what you do."
          }
          cap1={"INNOVATE"}
          cap2={"INTRODUCE"}
          cap3={"INSTIGATE"}
        />
        <MiddleT
          img={backed1}
          className="snap"
          color={"#FFF"}
          colorH1={"#231F20"}
          colorB={"#F36600"}
          colorM={"#000"}
          head1={"WHO WE ARE"}
          head2={"&"}
          head3={"WHAT WE DO"}
          text={
            "VIBA, well known as 'Vaagdevi Incubation and Business Accelerator' is an organization with entrepreneurial interface. It is established as a registered society back in 2019 with an agenda to foster entrepreneurs and flourish an aura of entrepreneurship among students."
          }
        />
        <MiddleT
          className="snap"
          color={"#F36600"}
          colorH1={"#FFF"}
          colorB={"#FFF"}
          colorM={"#FFF"}
          head1={"WE PROVIDE"}
          text={
            "VIBA is one of the platforms that brings out the best talents and enable them explore in their domain of interest. We support you in the following ways to excel yourself."
          }
        />

        {/* <Grid
        item
        xs={12}
        sm={12}
        md={6}9
        lg={8}
        xl={8}
        alignContent="center"
        alignItems="center"
        justify="center"
      >
        <Paper
          elevation={0}
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "3vh 5vh",
            margin: "0vh 3vh",
          }}
        >
          <Typography
            variant="subtitle1"
            align="start"
            style={{ wordBreak: "break-all" }}
          >
            <b
              style={{
                fontSize: "2vmax",
                fontWeight: "bolder",
                color: "#F93822FF",
              }}
            >
              ✓{" "}
            </b>
            Pitching Ideation.
          </Typography>
          <Typography
            variant="subtitle1"
            align="start"
            style={{ margin: "1vh 0vh", wordBreak: "break-all" }}
          >
            <b
              style={{
                fontSize: "2vmax",
                fontWeight: "bolder",
                color: "#F93822FF",
              }}
            >
              ✓{" "}
            </b>
            BIZRISE (Hackathons, Codethon, and Customer-on)
          </Typography>
          <Typography
            variant="subtitle1"
            align="start"
            style={{ margin: "1vh 0vh", wordBreak: "break-all" }}
          >
            <b
              style={{
                fontSize: "2vmax",
                fontWeight: "bolder",
                color: "#F93822FF",
              }}
            >
              ✓{" "}
            </b>
            Supporting business plan and competitions.
          </Typography>
          <Typography
            variant="subtitle1"
            align="start"
            style={{ margin: "1vh 0vh", wordBreak: "break-all" }}
          >
            <b
              style={{
                fontSize: "2vmax",
                fontWeight: "bolder",
                color: "#F93822FF",
              }}
            >
              ✓{" "}
            </b>
            Provide Internship Opportunities.
          </Typography>
          <Typography
            variant="subtitle1"
            align="start"
            style={{ margin: "1vh 0vh", wordBreak: "break-all" }}
          >
            <b
              style={{
                fontSize: "2vmax",
                fontWeight: "bolder",
                color: "#F93822FF",
              }}
            >
              ✓{" "}
            </b>
            Organize Workshops.
          </Typography>
          <Typography
            variant="subtitle1"
            align="start"
            style={{ margin: "1vh 0vh", wordBreak: "break-all" }}
          >
            <b
              style={{
                fontSize: "2vmax",
                fontWeight: "bolder",
                color: "#F93822FF",
              }}
            >
              ✓{" "}
            </b>
            Provide sessions on Industrial and Domain expertise to help the
            student community.
          </Typography>
        </Paper>
      </Grid> */}
        <Container max-Width="md">
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              backgroundRepeat: "no-repeat",
              backgrouundPosition: "center",
              backgroundSize: "cover",
              backgroundImage: `url(${ddd})`,
            }}
          >
            {miss.map((dat, index) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                classname="cardAb"
              >
                <Paper
                  elevation={7}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  className="paperAb"
                >
                  <img src={dat.img} alt="Mission" />
                  <ThemeProvider theme={theme}>
                    <Typography
                      variant="h4"
                      align="center"
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        fontWeight: "bolder",
                        color: "#F36600",
                        margin: "1.6vh 0vh",
                      }}
                    >
                      {dat.name}
                    </Typography>
                  </ThemeProvider>
                  <ThemeProvider theme={theme}>
                    <Typography
                      variant="subtitle1"
                      align="center"
                      style={{
                        wordBreak: "break-all",
                        fontFamily: "Montserrat, sans-serif",
                        fontWeight: "430",
                        color: "#000",
                      }}
                    >
                      {dat.data}
                    </Typography>
                  </ThemeProvider>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ backgroundColor: "#F2F6FA" }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ThemeProvider theme={theme}>
              <Typography
                variant="h5"
                align="center"
                style={{
                  fontWeight: "bolder",
                  margin: "5vh 0vh 0vh 0vh",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Our
                <Typography
                  variant="h4"
                  align="center"
                  style={{
                    fontWeight: "bolder",
                    color: "#F36600",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  SERVICES
                </Typography>
              </Typography>
            </ThemeProvider>
          </Grid>
          {serve.map((dat, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Paper
                elevation={7}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
                className="paperAbM"
              >
                <img src={dat.img} alt="Mission" />
                <ThemeProvider theme={theme}>
                  <Typography
                    variant="h5"
                    align="center"
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "bolder",
                      color: "#F36600",
                      margin: "1.6vh 0vh",
                    }}
                  >
                    {dat.name}
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <Typography
                    variant="subtitle1"
                    align="justify"
                    style={{
                      wordBreak: "break-all",
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "430",
                      color: "#000",
                    }}
                  >
                    {dat.data}
                  </Typography>
                </ThemeProvider>
              </Paper>
            </Grid>
          ))}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ backgroundColor: "#FFF" }}
        >
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            alignContent="center"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <ThemeProvider theme={theme}>
                <Typography
                  variant="h5"
                  align="center"
                  style={{
                    fontWeight: "bolder",
                    margin: "5vh 0vh",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  Our
                  <Typography
                    variant="h4"
                    align="center"
                    style={{
                      fontWeight: "bolder",
                      color: "#F36600",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    ACHEIVEMENTS
                  </Typography>
                </Typography>
              </ThemeProvider>
            </Grid>
            {aches.map((text, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={4}
                alignContent="center"
                alignItems="center"
                justify="center"
                style={{ padding: "5vh 2vh" }}
              >
                <Paper
                  elevation={5}
                  style={{ position: "relative", padding: "3vh 1.7vh" }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      variant="h2"
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        fontWeight: "bolder",
                        position: "absolute",
                        right: "2%",
                        top: "2%",
                        opacity: "0.5",
                        color: "#F36600",
                      }}
                    >
                      {index + 1}
                    </Typography>
                  </ThemeProvider>
                  <ThemeProvider theme={theme}>
                    <Typography
                      variant="h5"
                      align="start"
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        fontWeight: "bolder",
                        margin: "1vh 0vh 3vh 0vh",
                        color: "#000000",
                      }}
                    >
                      {text.name}
                    </Typography>
                  </ThemeProvider>
                  <ThemeProvider theme={theme}>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      align="start"
                      style={{ fontFamily: "Montserrat, sans-serif" }}
                    >
                      {text.ache}
                    </Typography>
                  </ThemeProvider>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
        {/*<Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          alignContent="center"
          alignItems="center"
          justify="center"
        >
          <Typography
            variant="h5"
            align="center"
            style={{ fontWeight: "bolder", margin: "3vh 0vh" }}
          >
            Our
            <Typography
              variant="h4"
              align="center"
              style={{ fontWeight: "bolder", color: "#F93822FF" }}
            >
              Partners
            </Typography>
          </Typography>
        </Grid>
      {part.map((text, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={3}
            alignContent="center"
            alignItems="center"
            justify="center"
            style={{ padding: "5vh" }}
          >
            <Paper
              elevation={5}
              square
              style={{ padding: "3vh", height: "18vh", margin: "3vh" }}
            >
              <img src={part[index]} height="100%" width="100%" />
            </Paper>
          </Grid>
        ))}*/}
      </Grid>
    </ThemeProvider>
  );
}
