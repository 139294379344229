 import React from 'react';
import "./detail.css"
import Evee from "../events1/evee/Evee"
import ScheduleIcon from '@material-ui/icons/Schedule';
import { Button } from '@material-ui/core';
import Popup from "reactjs-popup";




const Detail = () => {
    const details =[
        {title:"SIH internal hackathon",part:"120 teams",evestatus:"Event has completed",deadline:"completed",partstatus:"participated",availability:"unlimited",about:"This event is an internal hackathon for SIH conducted by VIBA in vaagdevi colleges. There were two levels of selection for each team where students were given 5 minutes per team in first round and 2 minutes in the second round. They were allowed to pitch there ideas in the given time and the top teams were selected as finalists.",outcomes1:"The selected teams from the college where allowed to participate in the Smart India Hackathon",outcomes2:"The selected teams will get the opportunity to face the challenge of solving real time problems across the nation.",outcomes3:"The participants will gain an experience of pitching ideas and approaching real time problems.",moduletype:"Hackathon",duration:"six and half  hours",available:"unlimited",lang:"English",targaud:"B.Tech students of all branches",location:"Vaagdevi colleges, Bollikunta, Warangal.",fromdate:"23-01-2020",todate:"23-01-2020",timings:" 9:30am to 7:00pm",s1:"Dr A.Chandramouli",s1d:"Dean Vaagdevi group of colleges",s2:"Dr MK.Kaushik",s2d:"CEO of VIBA",contact:"+91 9542117888",email:"vibafoundation@gmail.com",g1:"Dr K.Prakash",g1d:"Principal VCE",g2:"Dr P.Prasad Rao",g2d:"Principal VEC",spon1:"Skillship foundation"}
    ]
    return (
       
        <div className="deatail-maino">
             {details.map((details, index) => (
                
                 <>
            <div className="detail-slider">
                    SLIDER BLOCK
                    
            </div>
           
            <div className="detail-apply-block">
                 <div className="heading-status-grid">
                     <div className="h-s-left">
                         <div className="h-s-left-items">
                           <h1> {details.title}</h1>
                           <div className="items-po-12">
                           <div className="item1-po-1">
                              <img src={require('./icons/mod1.png')} style={{width:"4vh",height:"4vh",margin:"1vh 1vh"}} />
                              
                              <h2> {details.fromdate} </h2>
                              </div>
                              <div className="item1-po-1">
                              <img src={require('./icons/team1.png')} style={{width:"4vh",height:"4vh",margin:"1vh 1vh"}} />
                              
                              <h2> {details.part} {details.partstatus} </h2>
                              </div>
                              <div className="item1-po-1">
                              <img src={require('./icons/loc1.png')} style={{width:"4vh",height:"4vh",margin:"1vh 1vh"}} />
                        
                              <h2> {details.location} </h2>
                              </div>
                           </div>
                        
                         </div>
                     </div>
                     <div className="h-s-right">
                        <div className="button">
                            <Button>
                                {details.evestatus}
                            </Button>
                            <Popup contentStyle={{width: "90%"}}  trigger={<button className="button"> Open Modal </button>} popup-content modal>
                                
    {close => (
      <div className="modal">
        <a className="close" onClick={close}>
          &times;
        </a>
        <div className="header"> Modal Title </div>
        <div className="content">
    
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, a nostrum.
          Dolorem, repellat quidem ut, minima sint vel eveniet quibusdam voluptates
          delectus doloremque, explicabo tempore dicta adipisci fugit amet dignissimos?
          <br />
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur sit
          commodi beatae optio voluptatum sed eius cumque, delectus saepe repudiandae
          explicabo nemo nam libero ad, doloribus, voluptas rem alias. Vitae?
        </div>
        <div className="actions">
         
         {/*  <button
            className="button"
            onClick={() => {
              console.log("modal closed ");
              close();
            }}
          >
            close modal
          </button> */}
        </div>
      </div>
    )}
  
  </Popup>
                        </div>
                     </div>
                 </div>
            </div>
             
            <div className="sub-detail-block">

               <div className="sub-sub-left">
                      <div className="sub-sub-left-items">
                          <div className="left-block-1">
                             <div className="left-about">
                                 <h1>ABOUT THE MODULE: </h1>
                                  <p>
                                      {details.about}
                                  </p>
                                <h2>
                                Learning outcomes:
                                </h2>
                                <p className="list-1">
                                    <h3>-{details.outcomes1}</h3>
                                    <h3>-{details.outcomes2}</h3>
                                    <h3>-{details.outcomes3}</h3>
                                </p>
                             </div>
                          </div>
                          <div className="left-block-2">
                          <h1>RESOURCE PERSONS:</h1>
                             <div className="left-block-2-items">
                                 
                                <div className="left-block-2-dec">
                                
                                <h1>{details.s1}</h1>
                                <h2>{details.s1d}</h2>
                                
                                </div>
                                <div className="left-block-2-img">
                                   
                                </div>
                             </div>
                             <div className="left-block-2-items">
                                <div className="left-block-2-dec">
                                <h1>{details.s2}</h1>
                                <h2>{details.s2d}</h2>
                                </div>
                                <div className="left-block-2-img">
                                   
                                </div>
                             </div>
                          </div>
                          <div className="left-block-2">
                          <h1>GUESTS:</h1>
                             <div className="left-block-2-items">
                                 
                                <div className="left-block-2-dec">
                                
                                <h1>{details.g1}</h1>
                                <h2>{details.g1d}</h2>
                                
                                </div>
                                <div className="left-block-2-img">
                                   
                                </div>
                             </div>
                             <div className="left-block-2-items">
                                <div className="left-block-2-dec">
                                <h1>{details.g2}</h1>
                                <h2>{details.g2d}</h2>
                                </div>
                                <div className="left-block-2-img">
                                   
                                </div>
                             </div>
                          </div>
                          <div className="left-block-3">
                                 <div className="right-block-12">
                                     <h1>PATNERS:</h1>
                                      <div className="list-of-patners">
                                          <div className="patner1">
                                              <img src={require('../img/SF.png')} style={{width:"100%",height:"100%"}} />
                                          </div>
                                          <div className="patner2"></div>
                                          <div className="patner3"></div>
                                          <div className="patner4"></div>
                                      </div>
                                 </div>
                          </div>
                      </div>
               </div>

               <div className="sub-sub-right">
                    <div className="sub-sub-right-items">
                      
                        <div className="right-block-1">
                        <h1>MODULE DETAILS:</h1>
                          <div className="items-po">
                              <div className="item1-po">
                              <img src={require('./icons/mod1.png')} style={{width:"4vh",height:"4vh"}} />
                              <h2>Module type:     </h2>
                              <h2> {details.moduletype} </h2>
                              </div>
                              <div className="item1-po" style={{margin:"1vh 0vh"}}>
                              <img src={require('./icons/dur1.png')} style={{width:"4vh",height:"4vh"}} />
                              <h2>Duration:    </h2>
                              <h2> {details.duration} </h2>
                              </div>
                              <div className="item1-po">
                              <img src={require('./icons/cap1.png')} style={{width:"4vh",height:"4vh"}} />
                              <h2>Capacity:     </h2>
                              <h2> {details.availability} </h2>
                              </div>
                              <div className="item1-po" style={{margin:"2vh 0vh"}}>
                              <img src={require('./icons/lang1.png')} style={{width:"4vh",height:"4vh"}} />
                              <h2>Language:     </h2>
                              <h2> {details.lang} </h2>
                              </div>
                              <div className="item1-po">
                              <img src={require('./icons/team (1).png')} style={{width:"4vh",height:"4vh"}} />
                              <h2>Target audience:     </h2>
                              <h2> {details.targaud} </h2>
                              </div>
                          </div>
                        </div>
                    
                        <div className="right-block-1">
                            <h1>VENUE & TIMINGS</h1>
                        <div className="item1-po-2">
                              <img src={require('./icons/loc1.png')} style={{width:"4vh",height:"4vh",margin:"1vh 1vh"}} />
                              
                              <h2> {details.location} </h2>
                              </div>
                              <div className="item1-po-2">
                              <img src={require('./icons/calendar (3).png')} style={{width:"4vh",height:"4vh",margin:"1vh 1vh"}} />
                              
                                  <h2> {details.fromdate}  to {details.todate},{details.timings}</h2>
                              </div>
                        </div>


                        <div className="right-block-1">
                            <h1>CONTACT DETAILS</h1>
                        <div className="item1-po-2">
                              <img src={require('./icons/phone.png')} style={{width:"4vh",height:"4vh",margin:"1vh 1vh"}} />
                              
                              <h2> {details.contact} </h2>
                              </div>
                              <div className="item1-po-2">
                              <img src={require('./icons/mail.png')} style={{width:"4vh",height:"4vh",margin:"1vh 1vh"}} />
                              
                                  <h2 style={{textTransform:"lowercase"}}> {details.email} </h2>
                              </div>
                        </div>
 

                    </div>
               </div>

            </div>
          {/*   <Evee /> */}
</>
             ))}

        </div>
        
    );
}

export default Detail;
