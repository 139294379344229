import React from "react";
import "../pages/pages.css";
import { Grid, Typography, Paper, Divider } from "@material-ui/core";

export default function TeamU(props) {
  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      style={{ width: "100vw" }}
    >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography
          variant="h4"
          align="left"
          style={{
            color: "#000",
            margin: "3vh 7vh",
            fontWeight: "bolder",
            textTransform: "uppercase",
          }}
        >
          VIBA {props.title}
        </Typography>
        <Divider
          style={{
            margin: "0vh 7vh",
            backgroundColor: "#F36600",
            height: "2.4px",
          }}
        />
      </Grid>
      {props.data.map((dot, index) => (
        <Grid item xs={12} sm={6} md={6} lg={3} xl={3} className="cardTe">
          <Paper
            elevation={7}
            className="board"
            style={{ borderRadius: "9px" }}
          >
            <img src={dot.img} alt={dot.title} />
            <div className="teamT">
              <Typography
                variant="h6"
                align="center"
                style={{
                  color: "#F36600",
                  fontWeight: "bolder",
                }}
              >
                {dot.title}
              </Typography>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                align="center"
                style={{
                  fontWeight: "bolder",
                }}
              >
                {dot.job}
              </Typography>
            </div>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
