import React, { useCallback, useState, Children, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { AuthContext } from "../../config/auth";
import base from "../../config/FbConfig";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "sticky",
    top: 0,
    backgroundColor: "#F36600",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  input: {
    display: "none",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PopUp(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState("");
  const [url, setUrl] = React.useState("");
  const { userId, userName } = useContext(AuthContext);

  const handleImg = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };
  const handleImgUpload = () => {
    const uploadTask = base.storage().ref(`Profile/${userName}`).put(image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        console.log("snapshot");
      },
      (error) => {
        console.log(error);
      },
      () => {
        base
          .storage()
          .ref("Profile")
          .child(userName)
          .getDownloadURL()
          .then((url) => {
            setUrl(url);
          });
      }
    );
  };
  const db = base.firestore();
  const handleUpload = () => {
    var user = base.auth().currentUser;
    return user
      .updateProfile({
        photoURL: url,
      })
      .then(() => {
        return db.collection("UserData").doc(userId).update({
          photo: url,
        });
      })
      .then(function () {
        alert("Image successfully updated!");
      })
      .catch(function (error) {
        console.error("Error updating document: ", error);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        style={{ margin: "3vh 0vh", color: "#FFF", backgroundColor: "#F36600" }}
        onClick={handleClickOpen}
        size="small"
      >
        update profile
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography
              variant="h6"
              className={classes.title}
              style={{ color: props.colorM }}
            >
              DISPLAY IMAGE UPDATE
            </Typography>
            <IconButton
              autoFocus
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                variant="h5"
                color="textPrimary"
                align="center"
                style={{
                  width: "70%",
                  fontWeight: "450",
                  letterSpacing: "1.2px",
                  fontFamily: "Montserrat, sans-serif",
                  margin: "2vh",
                }}
              >
                ADD AN IMAGE
              </Typography>
            </ThemeProvider>

            <input
              style={{ margin: "2.7vh" }}
              accept="image/*"
              name="img"
              multiple
              type="file"
              onChange={handleImg}
              className={classes.input}
              id="contained-button-file"
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#F36600",
                  color: "#FFF",
                  margin: "2.7vh",
                }}
                component="span"
              >
                select image
              </Button>
            </label>
            <Button
              variant="outlined"
              color="textprimary"
              onClick={handleImgUpload}
              style={{ margin: "2vh 0vh" }}
            >
              Upload Image
            </Button>

            <img
              style={{ margin: "2.7vh", borderRadius: "50%" }}
              src={url}
              height="250"
              width="250"
            />
            <Button
              variant="contained"
              style={{
                backgroundColor: "#F36600",
                color: "#FFF",
                margin: "2.7vh",
              }}
              component="span"
              onClick={handleUpload}
            >
              Update Image
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
