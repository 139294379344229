import React, { useState } from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import "./Comps.css";
import PopUp from "./PopUp";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

export default function MiddleT(props) {
  const [popup, setPopup] = useState(props.popup);
  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      style={{
        backgroundColor: props.color,
        backgroundImage: `url(${props.img})`,
        backgroundRepeat: "no-repeat",
        backgrouundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="LeftT">
        <ThemeProvider theme={theme}>
          <Typography
            variant="h3"
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "bold",
              color: props.colorH1,
            }}
          >
            {props.head1}
            <Typography
              variant="h3"
              style={{
                fontWeight: "bold",
                color: "#F36600",
                fontFamily: "Montserrat, sans-serif",
              }}
              align="center"
            >
              {props.head2}

              <Typography
                variant="h3"
                color="textSecondary"
                style={{
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                {props.head3}
              </Typography>
            </Typography>
          </Typography>
        </ThemeProvider>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={8} xl={8} className="RightT">
        <ThemeProvider theme={theme}>
          <Typography
            variant="h6"
            style={{
              fontFamily: "Montserrat, sans-serif",
              letterSpacing: "1.2px",
              wordSpacing: "3px",
              color: props.colorM,
              paddingBottom: "1.7vh",
              textAlign: "left",
            }}
          >
            {props.text}
          </Typography>
        </ThemeProvider>
        {popup ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "1.2vh",
            }}
          >
            <ArrowForwardRoundedIcon style={{ color: props.colorB }} />
            <PopUp color={props.colorB} />
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
}
