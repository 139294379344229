import React from "react";
import "./fotter.css";
import { Grid, Typography, TextField, Button, Avatar } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import MailRoundedIcon from "@material-ui/icons/MailRounded";
import PhoneRoundedIcon from "@material-ui/icons/PhoneRounded";
import { makeStyles } from "@material-ui/core/styles";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import VibaO from "./vibaO.png";
const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(32),
    height: theme.spacing(19),
    backgroundColor: "#FFF",
    paddingTop: "2vh",
    fontFamily: "Montserrat, sans-serif",
  },
}));

const Fotter = () => {
  let theme = createMuiTheme();
  theme = responsiveFontSizes(theme);
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ backgroundColor: "#000" }}
      >
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="contF">
          <ThemeProvider theme={theme}>
            <Typography
              variant="h5"
              align="left"
              style={{ fontWeight: "900", color: "#FFF" }}
            >
              STAY CONNECTED
            </Typography>
          </ThemeProvider>

          <ThemeProvider theme={theme}>
            <Typography
              variant="subtitle1"
              align="left"
              style={{ fontWeight: "700", color: "#FFF", padding: "2vh 0vh" }}
            >
              Join over 8500+ people who receive bi-weekly notifications on our
              Blogs
            </Typography>
          </ThemeProvider>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <TextField
              required
              variant="outlined"
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              style={{
                color: "#FFF",
                backgroundColor: "#FFF",
                maxWidth: "100%",
              }}
            />
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#F36600",
                margin: "0vh 0.7vh",
                color: "#FFF",
              }}
            >
              Sign Up
            </Button>
          </div>
          <div className="form">
            <div className="phn-no9">
              <PhoneRoundedIcon
                style={{
                  fontSize: "3vh",
                  margin: "0vh 1vh 0vh 0vh",
                  color: "#ffffff",
                }}
              />
              <h2>+91 9885545514</h2>
            </div>
            <div className="phn-no9">
              <MailRoundedIcon
                style={{
                  fontSize: "3vh",
                  margin: "0vh 1vh 0vh 0vh",
                  color: "#ffffff",
                }}
              />
              <h2>Vibafoundation@gmail.com</h2>
            </div>
          </div>

          <div className="link">
            <Link
              to={{
                pathname: "https://www.facebook.com/vaagdevi.colleges.18",
              }}
              target="_blank"
            >
              <FacebookIcon
                style={{
                  color: "#fff",
                  margin: "1.6vh",
                  fontSize: "3.6vh 3.6vh 3.6vh 0vh",
                }}
              />
            </Link>
            <Link
              to={{
                pathname: "https://instagram.com/___viba__?igshid=mu7qora4ybqb",
              }}
              target="_blank"
            >
              <InstagramIcon
                style={{
                  color: "#fff",
                  margin: "1.6vh",
                  fontSize: "3.6vh",
                }}
              />
            </Link>
            <Link
              to={{
                pathname:
                  "https://www.youtube.com/channel/UCRqcdVRlH7nLdD3VsQQ9wWQ",
              }}
              target="_blank"
            >
              <YouTubeIcon
                style={{
                  color: "#fff",
                  margin: "1.6vh",
                  fontSize: "3.6vh",
                }}
              />
            </Link>
            <Link
              to={{
                pathname:
                  "https://www.linkedin.com/company/viba-vaagdevi-incubation-and-business-accelerator",
              }}
              target="_blank"
            >
              <LinkedInIcon
                style={{
                  color: "#fff",
                  margin: "1.6vh",
                  fontSize: "3.6vh",
                }}
              />
            </Link>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="contF">
          <ThemeProvider theme={theme}>
            <Typography
              variant="h5"
              align="left"
              style={{ fontWeight: "900", color: "#FFF" }}
            >
              COMMITTED FOR CHANGE
            </Typography>
          </ThemeProvider>

          <ThemeProvider theme={theme}>
            <Typography
              variant="subtitle1"
              align="left"
              style={{ fontWeight: "700", color: "#FFF", padding: "2vh 0vh" }}
            >
              We try to change and inspire the minds of young entrepreneurs, but
              our terms of molding is slightly fascinating and engaging. Like a
              disclaimer, we assure a sure shot development upon joining us with
              a motive to grow.
            </Typography>
          </ThemeProvider>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              variant="rounded"
              src={VibaO}
              className={classes.large}
              align="left"
            ></Avatar>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="contFN">
          <ThemeProvider theme={theme}>
            <Typography
              variant="h4"
              align="center"
              style={{ fontWeight: "900", color: "#FFF" }}
            >
              NAVIGATE
            </Typography>
          </ThemeProvider>

          <ul>
            <Link to="/team" style={{ textDecoration: "none" }}>
              <li>
                <ThemeProvider theme={theme}>
                  <Typography
                    align="center"
                    variant="h6"
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "bold",
                      color: "#FFF",
                    }}
                  >
                    Team
                  </Typography>
                </ThemeProvider>
              </li>
            </Link>
            <Link to="/startups" style={{ textDecoration: "none" }}>
              <li>
                <ThemeProvider theme={theme}>
                  <Typography
                    align="center"
                    variant="h6"
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "bold",
                      color: "#FFF",
                    }}
                  >
                    Startup
                  </Typography>
                </ThemeProvider>
              </li>
            </Link>
            <Link to="/about" style={{ textDecoration: "none" }}>
              <li>
                <ThemeProvider theme={theme}>
                  <Typography
                    align="center"
                    variant="h6"
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "bold",
                      color: "#FFF",
                    }}
                  >
                    About us
                  </Typography>
                </ThemeProvider>
              </li>
            </Link>
            <Link to="/blog" style={{ textDecoration: "none" }}>
              <li>
                <ThemeProvider theme={theme}>
                  <Typography
                    align="center"
                    variant="h6"
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "bold",
                      color: "#FFF",
                    }}
                  >
                    Blog
                  </Typography>
                </ThemeProvider>
              </li>
            </Link>
          </ul>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div
            style={{
              maxWidth: "100vw",
              height: "0.3vh",
              backgroundColor: "#FFF",
              margin: "2vh 2vh 0.7vh 2vh",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                variant="h6"
                align="center"
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#FFF",
                }}
              >
                VAAGDEVI INCUBATION AND BUSINESS ACCELERATOR
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <Typography
                variant="subtitle1"
                align="center"
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#FFF",
                  margin: "2vh 3vh",
                }}
              >
                - Vaagdevi colleges, Bollikunta - warangal - telangana - 506001
              </Typography>
            </ThemeProvider>
          </div>
          <div
            style={{
              maxWidth: "100vw",
              height: "0.3vh",
              backgroundColor: "#FFF",
              margin: "0.7vh 2vh 2vh 2vh",
            }}
          ></div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "3vh",
          }}
        >
          <ThemeProvider theme={theme}>
            <Typography
              variant="subtitle2"
              align="center"
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
                color: "#FFF",
              }}
            >
              PRIVACY POLICY - TERMS & CONDITIONS - LEGAL
            </Typography>
          </ThemeProvider>

          <ThemeProvider theme={theme}>
            <Typography
              variant="subtitle2"
              align="center"
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
                color: "#FFF",
              }}
            >
              © 2020 vibafoudation.com ALL RIGHTS RESERVED.
            </Typography>
          </ThemeProvider>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Fotter;
