import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Counter from "./Counter";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
    "& > *": {
      marginTop: theme.spacing(5),
      minWidth: theme.spacing(25),
      maxWidth: theme.spacing(25),
      minHeight: theme.spacing(30),
      borderRadius: 14,
      backgroundColor: "#F5F8FA",
      padding: "1vh 0vh",
    },
  },
}));

export default function SimplePaper() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Typography
          variant="h5"
          align="center"
          style={{
            marginTop: "2vh",
            fontWeight: "bold",
          }}
        >
          <i
            style={{
              fontSize: "36px",
              paddingRight: "1vh",
              color: "#FF004F",
            }}
          >
            #
          </i>
          StartUp's
        </Typography>
        <Typography
          variant="h5"
          align="center"
          style={{
            textAlign: "center",
            marginBottom: "2vh",
            fontWeight: "bold",
          }}
        >
          With Us...
        </Typography>
        <Counter />
      </Paper>
    </div>
  );
}
