import React, { useCallback, useState, Children, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { Typography, Grid, List } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import { AuthContext } from "../../../config/auth";
import base from "../../../config/FbConfig";
import firebase from "firebase";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      padding: "3vh",
      minWidth: "81%",
      margin: "3vh auto",
    },
  },
  appBar: {
    position: "sticky",
    top: 0,
    backgroundColor: "#F36600",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  input: {
    display: "none",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BlogWite() {
  const { userId, userName } = useContext(AuthContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [category, setCategory] = React.useState("");
  const [image, setImage] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [blog, setBlog] = React.useState({ title: "", intro: "" });
  const [list, setList] = useState([{ subHeading: "", subMatter: "" }]);

  const add = () => {
    setList([...list, { subHeading: "", subMatter: "" }]);
  };
  const updateField = (e, index) => {
    const values = [...list];
    values[index][e.target.name] = e.target.value;
    setList(values);
  };

  const updateChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setBlog((prevBlog) => ({
      ...prevBlog,
      [name]: value,
    }));
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /*  const [head, setHead] = React.useState([
    { number: 0, head: `subHead${0}`, matter: `subMat${0}` },
  ]); */
  //console.log(list);
  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const handleImg = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };
  const handleImgUpload = () => {
    const uploadTask = base.storage().ref(`Blogs/${blog.title}`).put(image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // console.log("snapshot");
      },
      (error) => {
        //console.log(error);
      },
      () => {
        base
          .storage()
          .ref("Blogs")
          .child(blog.title)
          .getDownloadURL()
          .then((url) => {
            setUrl(url);
          });
      }
    );
  };
  const db = base.firestore();
  const handleBlog = (event) => {
    event.preventDefault();
    const { title, intro } = event.target.elements;

    return db
      .collection("VibaBlog")
      .doc(userId)
      .collection("Blogs")
      .doc(title.value)
      .set({
        img: url,
        title: title.value,
        Introduction: intro.value,
        matter: list,
        postedTime: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        return db.collection("LatestBlogs").doc(userId).set({
          img: url,
          title: title.value,
          Introduction: intro.value,
          matter: list,
          postedTime: firebase.firestore.FieldValue.serverTimestamp(),
        });
      })
      .then(() => {
        alert("Your Blog is Uploaded");
      })

      .catch((error) => {
        //console.error("Error adding document: ", error);
      });
  };
  //console.log(ques);

  return (
    <div>
      <Button
        variant="contained"
        style={{ color: "#FFF", backgroundColor: "#F36600" }}
        onClick={handleClickOpen}
      >
        Lets Go
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography
              variant="h5"
              className={classes.title}
              style={{ color: "#FFF" }}
            >
              BLOG
            </Typography>
            <IconButton
              autoFocus
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <form
          className={classes.root}
          onSubmit={handleBlog}
          validate
          autoComplete="off"
        >
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <ThemeProvider theme={theme}>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  style={{
                    width: "81%",
                    fontWeight: "450",
                    letterSpacing: "1.2px",
                    fontFamily: "Montserrat, sans-serif",
                    margin: "3vh auto 0.9vh auto",
                    textTransform: "uppercase",
                  }}
                >
                  Your Blog Title...
                </Typography>
              </ThemeProvider>
              <TextField
                style={{ padding: "1vh 0vh" }}
                value={blog.title}
                onChange={updateChange}
                name="title"
                autoFocus
                id="title"
                color="textPrimary"
                placeholder="Enter your Title here"
                multiline
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <ThemeProvider theme={theme}>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  style={{
                    width: "81%",
                    fontWeight: "450",
                    letterSpacing: "1.2px",
                    fontFamily: "Montserrat, sans-serif",
                    margin: "3vh auto 0.9vh auto",
                    textTransform: "uppercase",
                  }}
                >
                  Introduction...
                </Typography>
              </ThemeProvider>
              <TextField
                style={{ padding: "1vh 0vh" }}
                value={blog.intro}
                name="intro"
                id="intro"
                onChange={updateChange}
                color="textPrimary"
                placeholder="Introduction"
                multiline
                required
              />
            </Grid>
            {list.map((nap, index) => (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      variant="h5"
                      color="textPrimary"
                      style={{
                        width: "81%",
                        fontWeight: "450",
                        letterSpacing: "1.2px",
                        fontFamily: "Montserrat, sans-serif",
                        margin: "3vh auto 0.9vh auto",
                        textTransform: "uppercase",
                      }}
                    >
                      Sub-Heading...
                    </Typography>
                  </ThemeProvider>
                  <TextField
                    key={index}
                    style={{ padding: "1vh 0vh" }}
                    value={nap.subHeading}
                    name="subHeading"
                    onChange={(e) => updateField(e, index)}
                    id="subHeading"
                    color="textPrimary"
                    placeholder="Sub-Heading"
                    multiline
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      variant="h5"
                      color="textPrimary"
                      style={{
                        width: "81%",
                        fontWeight: "450",
                        letterSpacing: "1.2px",
                        fontFamily: "Montserrat, sans-serif",
                        margin: "3vh auto 0.9vh auto",
                        textTransform: "uppercase",
                      }}
                    >
                      write your story...
                    </Typography>
                  </ThemeProvider>
                  <TextField
                    key={index}
                    style={{ padding: "1vh 0vh" }}
                    value={nap.subMatter}
                    name="subMatter"
                    onChange={(e) => updateField(e, index)}
                    id="subMatter"
                    color="textPrimary"
                    placeholder="Your Story"
                    multiline
                    required
                  />
                </Grid>
              </>
            ))}

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div style={{ width: "81%", margin: "0vh auto" }}>
                <Button
                  variant="outlined"
                  color="textPrimary"
                  style={{ margin: "3vh 0vh" }}
                  onClick={add}
                >
                  Add Another Heading
                </Button>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <ThemeProvider theme={theme}>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  style={{
                    width: "81%",
                    fontWeight: "450",
                    letterSpacing: "1.2px",
                    fontFamily: "Montserrat, sans-serif",
                    margin: "3vh auto 0.9vh auto",
                  }}
                >
                  Add An Image...
                </Typography>
              </ThemeProvider>
              <div
                style={{
                  width: "81%",
                  margin: "3vh auto 0.9vh auto",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <input
                  style={{ margin: "2.7vh" }}
                  accept="image/*"
                  name="img"
                  multiple
                  type="file"
                  onChange={handleImg}
                  className={classes.input}
                  id="contained-button-file"
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#F36600", color: "#FFF" }}
                    component="span"
                  >
                    select image
                  </Button>
                </label>
                <Button
                  variant="outlined"
                  color="textprimary"
                  onClick={handleImgUpload}
                  style={{ margin: "2vh 0vh" }}
                >
                  Upload Image
                </Button>
                <img src={url} height="250" width="250" />
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "81%",
                margin: "0vh auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                className={classes.submit}
                style={{
                  backgroundColor: "#F36600",
                  color: "#FFF",
                  margin: "3vh 0vh",
                  padding: "2vh 7vh",
                }}
              >
                Post
              </Button>
            </div>
          </Grid>
        </form>
      </Dialog>
    </div>
  );
}
