import React from "react";
import Slider from "react-slick";
import "./evee.css";
import EveCard from "./EveCard";
import img1 from "./imageo/idea1.jpeg";
import img2 from "./imageo/idea2.jpeg";
import img3 from "./imageo/idea3.jpeg";
import img4 from "./imageo/idea4.jpeg";
import img5 from "./imageo/idea5.jpeg";
import img6 from "./imageo/idea6.jpeg";
import res1 from "./imageo/res1.jpeg";
import main from "../../Events/main.jpeg"
import imgg1 from "./imageo/sih1.jpeg";
import imgg2 from "./imageo/sih2.jpeg";
import imgg3 from "./imageo/sih3.jpeg";
import imgg4 from "./imageo/sih4.jpeg";
import imgg5 from "./imageo/sih5.jpeg";
import imgg6 from "./imageo/sih6.jpeg";
import imggg1 from "./imageo/hack.jpeg";
import imggg2 from "./imageo/hack2.jpeg";
import imggg3 from "./imageo/hack3.jpeg";
import imggg4 from "./imageo/hack4.jpeg";
import imggg5 from "./imageo/hack.jpeg";
import imggg6 from "./imageo/hack2.jpeg";
import main12 from "../agro.jpeg"
import hack from "./hack.jpg"
import "slick-carousel/slick/slick.css";
import ph3 from "../../Events/new1.jpeg"
import "slick-carousel/slick/slick-theme.css";
import { Grid, Button, Typography } from "@material-ui/core";

import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

export default function Evee() {
  const settings = {
    autoplay: true,
    pauseOnHover: false,
    fade: false,
    infinite: true,
    slidesToShow: 1,
    speed: 900,
    arrows: false,
    dots: false,
  };
  return (
    <>
  
    <div>
    
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{  backgroundColor: "#F36600" }}
      >
         
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          xl={6}
          style={{
            height: "70vh",
            maxWidth: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            padding: "5vh",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              backgroundImage: `url(${hack})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "contain",
            }}
          ></div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{
            width: "100%",
            height: "70vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            padding: "5vh 0vh",
          }}
        >
          <ThemeProvider theme={theme}>
            <Typography
              align="left"
              variant="h4"
              style={{
                width: "100%",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
                color: "#FFF",
                padding: "0vh 3vh 3vh 3vh",
                textAlign: "left",
              }}
            >
         HACK FOR NEED 2.0
            </Typography>
            <Typography
              variant="subtitle1"
              align="left"
              style={{
                width: "100%",
                fontFamily: "Montserrat, sans-serif",
                letterSpacing: "1.2px",
                wordSpacing: "3px",
                color: "#FFF",
                padding: "1.7vh 3vh",
                textAlign: "left",
              }}
            >
     VIBA going to  Organice 24hour  Oncampus  Hackthon for the need 2.O.

<p  style={{
              
                fontWeight: "bold",
              
              }}>Being  Innovative:</p>
The only way to Discover the  Limits of the Possible is to go beyond them into the Impossible. Be more innovative and make the reality true.
        
        </Typography>
          
          </ThemeProvider>
        </Grid>
      </Grid>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
      </Grid>
    </div>
    </>
  );
}
{
  /*  <Slider {...settings}>
        <div>
          <div>
            <EveCard
              he1={"SIH "}
              hetitle={"Smart India Hackathon"}
              hepara={
                "This is a national event conducted especially for B.Tech students for bringing out the innovative Ideation and to build the entrepreneurial skills in them laying a path towards enhancement of knowledge."
              }
              backhe1={imgg1}
              backhe2={imgg2}
              backhe3={imgg3}
              backhe4={imgg4}
              backhe5={imgg5}
              backhe6={imgg6}
            />
          </div>
        </div>
        <div>
          <div>
            <EveCard
              he1={" "}
              hetitle={"Freshmen Ideathon"}
              hepara={
                "This event is exclusively conducted in the college for the budding engineers to inbuilt the technical and non technical skills in them right from their 1 st year itself. This was a First successful in VIBA conducted purely by its volunteers. The top 3 finalists of the competition were recruited as VIBA incubates."
              }
              backhe1={img1}
              backhe2={img2}
              backhe3={img3}
              backhe4={img4}
              backhe5={img5}
              backhe6={img6}
            />
          </div>
        </div>
        <div>
          <div>
            <EveCard
              he1={""}
              hetitle={"Antharprerana"}
              hepara={
                "In this they were given the tasks of networking, group discussion, project idea, presentation, model making etc. which is on the motive way of inbuilting the entrepreneurship skills in the participants."
              }
              backhe1={imggg1}
              backhe2={imggg2}
              backhe3={imggg3}
              backhe4={imggg4}
              backhe5={imggg5}
              backhe6={imggg6}
            />
          </div>
        </div>
      </Slider> */
}
