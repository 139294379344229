import React, { useState, useEffect } from "react";

import "./style.css";
import back from "../pages/startup/back.png";
export default function Counter() {
  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (count > 11999) {
        clearInterval(intervalId);
      } else {
        setCount((prevCount) => prevCount + 50);
      }
    }, 1);

    return () => clearInterval(intervalId);
  }, [count]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (count1 > 124) {
        clearInterval(intervalId);
      } else {
        setCount1((prevCount1) => prevCount1 + 1);
      }
    }, 5);

    return () => clearInterval(intervalId);
  }, [count1]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (count2 > 17) {
        clearInterval(intervalId);
      } else {
        setCount2((prevCount2) => prevCount2 + 1);
      }
    }, 81);

    return () => clearInterval(intervalId);
  }, [count2]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (count3 > 6) {
        clearInterval(intervalId);
      } else {
        setCount3((prevCount3) => prevCount3 + 1);
      }
    }, 99);

    return () => clearInterval(intervalId);
  }, [count3]);
  return (
    <div>
      <div className="num3" style={{ backgroundImage: `url(${back})` }}>
        <div className="num4">
          <div className="num1">
            <h1>{count}+</h1>
            <h2>Students</h2>
          </div>
          <div className="num1">
            <h1>{count1}+</h1>
            <h2>Colleges</h2>
          </div>
          <div className="num1">
            <h1>{count2}+</h1>
            <h2>States</h2>
          </div>
          <div className="num1">
            <h1>{count3}+</h1>
            <h2>Countries</h2>
          </div>
        </div>
      </div>
    </div>
  );
}
