import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router";
import base from "../../config/FbConfig";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "3vh 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  margin: {
    marginTop: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "100%",
  },
  avatar: {
    margin: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Signup = ({ history }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const classes = useStyles();
  const [data, setState] = useState({
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    college: "",
    state: "",
    team: "",
    live: "",
    idea: "",
    startup: "",
    about: "",
  });

  const updateField = (e) => {
    e.preventDefault();
    setState({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSignUp = useCallback(
    async (event) => {
      event.preventDefault();
      const {
        userName,
        firstName,
        lastName,
        email,
        mobile,
        password,
        college,
        state,
        team,
        live,
        idea,
        startup,
        about,
      } = event.target.elements;
      const db = base.firestore();
      var users = null;
      // console.log(firstName + lastName + email + mobile + password);
      try {
        await base
          .auth()
          .createUserWithEmailAndPassword(email.value, password.value)
          .then((cred) => {
            users = cred.user;
            users.sendEmailVerification();
            users.updateProfile({
              displayName: userName.value,
            });
          })
          .then((cred) => {
            return db
              .collection("UserData")
              .doc(users.uid)
              .set({
                firstName: firstName.value,
                lastName: lastName.value,
                email: email.value,
                mobile: mobile.value,
                college: college.value,
                state: state.value,
                team: team.value,
                live: live.value,
                startup: startup.value,
                idea: idea.value,
                about: about.value,
              })
              .then(() => {
                alert(
                  `Hey ${
                    firstName.value + " " + lastName.value
                  } ...! You are now a part of VIBA`
                );
              });
          });
        history.push("/");
      } catch (error) {
        alert(error.message);
      }
    },
    [history]
  );

  return (
    <form className={classes.form} onSubmit={handleSignUp} validate>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid items xs={12} sm={12} md={6} lg={6} xl={6}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Paper
              variant="outlined"
              square
              elevation={0}
              style={{
                padding: "0vh 5vh",
                margin: "7vh 0vh",
              }}
            >
              <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                  Sign up
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      autoComplete="uName"
                      value={data.userName}
                      name="userName"
                      onChange={updateField}
                      required
                      fullWidth
                      id="userName"
                      label="User Name"
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="fname"
                      value={data.firstName}
                      name="firstName"
                      onChange={updateField}
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      value={data.lastName}
                      name="lastName"
                      onChange={updateField}
                      autoComplete="lname"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      value={data.email}
                      name="email"
                      onChange={updateField}
                      autoComplete="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="mobile"
                      label="Mobile Number"
                      value={data.mobile}
                      name="mobile"
                      onChange={updateField}
                      type="mobile"
                      autoComplete="mobile"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      className={clsx(classes.margin, classes.textField)}
                    >
                      <InputLabel htmlFor="standard-adornment-password">
                        Password
                      </InputLabel>
                      <Input
                        id="password"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        autoComplete="current-password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="college"
                      label="College/Oraganization/School"
                      value={data.college}
                      name="college"
                      onChange={updateField}
                      type="college"
                      autoComplete="college"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="state"
                      label="State"
                      value={data.state}
                      name="state"
                      onChange={updateField}
                      type="state"
                      autoComplete="state"
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Container>
        </Grid>
        <Grid items xs={12} sm={12} md={6} lg={6} xl={6}>
          <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Paper
              variant="outlined"
              square
              elevation={0}
              style={{
                padding: "0vh 5vh",
                margin: "7vh 0vh",
              }}
            >
              <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                  Please do Fill in the details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      autoComplete="team"
                      value={data.team}
                      name="team"
                      onChange={updateField}
                      required
                      fullWidth
                      id="team"
                      label="Team you belong to in VIBA"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      autoComplete="live"
                      value={data.live}
                      name="live"
                      onChange={updateField}
                      required
                      fullWidth
                      id="live"
                      label="No.of live projects you have worked"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      fullWidth
                      id="startup"
                      label="Name the startup you'r working with"
                      value={data.startup}
                      name="startup"
                      onChange={updateField}
                      autoComplete="startup"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      label="Share your Startup Idea if any."
                      id="Idea"
                      value={data.idea}
                      name="idea"
                      onChange={updateField}
                      autoComplete="idea"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="about"
                      label="Briefly describe yourself"
                      value={data.about}
                      name="about"
                      onChange={updateField}
                      type="about"
                      autoComplete="about"
                      multiline
                      rows={3}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="outlined"
                  color="default"
                  className={classes.submit}
                >
                  Sign Up
                </Button>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Link
                      to="/login"
                      style={{ cursor: "pointer", textDecoration: "none" }}
                    >
                      Already have an account? Sign in
                    </Link>
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Container>
        </Grid>
      </Grid>
    </form>
  );
};

export default withRouter(Signup);
