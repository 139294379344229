import React from "react";
import "./style.css";
import img26 from "../img/v.jpeg";
import { Grid, Typography, TextField, Button, Avatar } from "@material-ui/core";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
export default function Bout() {
  let theme = createMuiTheme();
  theme = responsiveFontSizes(theme);
  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      style={{ backgroundColor: "#F36600", minHeight: "81vh" }}
    >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="aboutC">
        <ThemeProvider theme={theme}>
          <Typography
            variant="h4"
            align="center"
            style={{
              fontWeight: "900",
              color: "#FFF",
              fontFamily: "Montserrat, sans-serif",
            }}
          >
            WHAT DOES VIBA DO?
          </Typography>
        </ThemeProvider>

        <ThemeProvider theme={theme}>
          <Typography
            variant="subtitle1"
            align="center"
            style={{
              fontWeight: "700",
              color: "#FFF",
              padding: "2vh 0vh",
              fontFamily: "Montserrat, sans-serif",
            }}
          >
            Tiny moves embolden to imprint huge leaps. All that requires is a
            spark that surges up to get launched into magnificent space. In
            similar lines, a miniscule vim is the most anticipated quality in an
            entrepreneur. VIBA has its space, open to occupy, to all such
            fanatics.
          </Typography>
        </ThemeProvider>
        <Link to="/about" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            style={{
              color: "#F36600",
              backgroundColor: "#FFF",
              fontFamily: "Montserrat, sans-serif",
            }}
          >
            Know more
          </Button>
        </Link>
        <div className="videoY">
          <iframe
            style={{ borderRadius: "16px" }}
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/qsc1YqtL-Vg"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </Grid>
    </Grid>
  );
}
