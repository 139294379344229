import React from 'react';
import "./mentolo.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'

export default function Mencard(props){
    return (
        <div className="main-men">
            <h1>Here’s what some of our most prominent visitors had to say:</h1>
            <div className="card-main-k">
                <div className="left-part-k">
                  <div className="circle-k" style={{backgroundImage: `url(${props.back})`}}>

                  </div>
                </div>
                <div className="right-part-k">
                    <div className="items-right">
                    <FontAwesomeIcon icon={faQuoteLeft} className="icon-k" />
                <p>{props.paratest}
</p>
    <h1>{props.nametest}</h1>
    <h2> {props.proftest}</h2>
                    </div>
               
                </div>
            </div>
        </div>
    );
}


