import React from "react";
import "./pages.css";
import { Grid, Typography, Paper, Button, Container } from "@material-ui/core";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import vibaS from "./startup/viba.png";
import brain from "./startup/Brain.png";
import ment from "./startup/mentoring.png";
import survey from "./startup/survey.png";
import back from "./startup/back.png";
import proto from "./startup/prototype.png";
import start from "./startup/market.png";
import bus from "./startup/bus.png";
import avasyu from "../layout/images/avasyu1.png";
import krushiseva from "../layout/images/krushiseva.png";
import sam from "../layout/images/sam1.png";
import that from "../layout/images/that1.png";
import ups from "../layout/images/ups1.png";
import ola from "../layout/images/ola1.png";
import Win from "../layout/images/Win1.png";
import laalm from "../layout/images/laalm1.png";
import kd from "../layout/images/kamdham.jpeg"
import igyan from "../layout/images/igyan.png"
import jaz from "../layout/images/jaz.png"
import sf from "../pages/img/sf.jpg"
import Top from "../Comps/Top";
import new1 from "../pages/startup/new.jpeg"
import sun from "../Comps/sun.png"
import MiddleT from "../Comps/MiddleT";
import MobileStepper from "@material-ui/core/MobileStepper";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { autoPlay } from "react-swipeable-views-utils";

import Popup from "reactjs-popup";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F6FA",
    maxWidth: "70%",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "18px",
  },

  img: {
    height: 322,
    width: "100%",
    margin: "0vh auto",
  },
}));

export default function () {
  const info = [
    {
      name: "Brainstorming",
      img: brain,
      flex: "flex-end",
      flexs: "flex-end",
    },
    {
      name: "Idea Refining",
      img: ment,
      flex: "center",
      flexs: "flex-end",
    },
    {
      name: "Literature Survey",
      flex: "flex-start",
      img: survey,
      flexs: "flex-end",
    },
    {
      name: "Prototyping",
      flex: "flex-end",
      img: proto,
      flexs: "flex-start",
    },

    {
      name: "Market Fit Product",
      img: start,
      flex: "center",
      flexs: "flex-start",
    },
    {
      name: "Business Development",
      img: bus,
      flex: "flex-start",
      flexs: "flex-start",
    },
  ];
  const active = [{img:igyan,cont:"Igyan",desc:<p><p>We have taken an oath to build a skillful India</p>
  <p>Igyan is an edutech company which is based out of India and established in the year 2021. Igyan's vision is to build a skillfull India.</p>
  <p>Nowadays, Industry is in a perspective that Indian graduates are not fit for Industry. Our vision is to prove them wrong, We Igyan inculcate skills in Engineering/Degree graduates through our course work where students can hike their skills by developing live projects. We provide internships to the best students to encourage them to build their careers as skilled graduates.</p></p>},
                  
                     {img:laalm,cont:"Life Achievement and Life Meditation (LAALM)",desc:<p>
                     <p>
                     Each one of human beings irrespective of age, gender, caste, status and creed has aspirations in life. We all work towards achieving these aspirations and in the process lose balance on various points due to emotions / feelings and values that are driven by our senses. Further, the life achievements need focus and attention which depends upon the finest instrument the human being has. You can only explore and mend the mind through meditation. As long as this meditative stature continues in life, your life goes smooth. Thus, your life achievement is engine and your life meditation is navigator of life to reach peaks of your existence.
                     </p>
                     <p>
                     Each one of human beings irrespective of age, gender, caste, status and creed has aspirations in life. We all work towards achieving these aspirations and in the process lose balance on various points due to emotions / feelings and values that are driven by our senses. Further, the life achievements need focus and attention which depends upon the finest instrument the human being has. You can only explore and mend the mind through meditation. As long as this meditative stature continues in life, your life goes smooth. Thus, your life achievement is engine and your life meditation is navigator of life to reach peaks of your existence.
                     </p>
                   </p>},
                   {
                    img:jaz,cont:"Jazzlike technologies  ",desc:<p>
                    We are working on trainings and placements. Our goal is to train students in a professional manner with real time applications. To provide trainings in low cost for rural development. Also we have an idea to start 2 real time projects. we are also looking forward to start our company as product based and services based, in a simple way that our vision is to work under all  kinds of services with different domains. 

our future idea is to develop our company as project based company.</p>

                  }, 
 
                  //   {img:sam,cont:"SAMSTHITHI",desc:<p>
                  //    <p>     Samsthithi is a great initiative by an an incubate of VIBA, which owes to deliver quality products to all the customers charging reasonable prices. This initiative is a product delivery service with reasonable prices, so that each customer can fulfill their requirement and also their personal satisfaction. </p>
                  //    <p>Samsthithi provides you daily products and seasonal products in a reasonable price. Samsthithi will deliver your products in time without any damage done to your product ordered. Samsthithi has already proved with the product selling of masks successfully, nearly 1000+ masks have been sold in less than a month and the Turnover of SAMSTHITHI is 13000+INR. We work under quality and best customer service. The next product will be eggs and some basic food requirements. Keeping this COVID-19 crisis in mind, Samsthithi provides the best delivery system following strict COVID-19 regulations because customer satisfaction and security are the first most ones.</p>
                  //  </p>}
//                    , {img:ups,cont:"UPSKOOL VALLEY ",desc:<p>
//                      <p>We love innovation and we believe that people tend to innovate and everyone have some kind of innovativeness irrespective of our acknowledgment towards them. As an ED-TECH company we think that educating people about all the latest technologies and latest educational real time projects, will help our students, budding engineers and rookie profilers to enhance their abilities, skill, knowledge and practical understanding. When the people like them gather, the innovation transpires  and gives birth to new innovative ideas. We give full opportunity to think and ideate,and we help them for their ideation and develop a better product inorder to solve the problems. We inspire them with our unique training sessions on latest technologies, and help them ideate the solution for the problems in different domains of the IT world, we also support them through our mentoring while encouraging them to be future ready. </p>
//                      <p> VOICE OF INNOVATION 

// This is an initiation to expose all the rural innovators by interviewing them and inspiring people in one or either way. We help their stories to reach out to the public, irrespective of their domains. We do all Kind of innovative stuff like, identifying problems, brainstorming, idea generation, consulting,modifying, developing a perfect product inorder to solve the problems. Alongside provide a platform and create opportunities for innovators to develop and deploy their projects/products. We create an Ecosystem where mentoring, supporting will help these innovative ideas convert into commercial products, apart from these we construct a cluster model where educational institutions collaborate, interact and share innovative ideas and make learning of latest technologies possible for every student in the particular institution irrespective of domains. We conduct events, hackathons and training sessions as a part of this. Like this our every practice of reaching innovation will be named as VOICE OF INNOVATION..!
// </p>
//                    </p>}
                  //   ,{img:Win,cont:"Winventive",desc:<p><p>Winventive is a kind of platform that proposes to offer interactive learning resources and online courses together for different educational domains. Basically, it is an E-learning platform. Winventive also provides various types of events and competitions to develop the skill set of the students. </p>
                  //   <p>Our vision is to create awareness among the students regarding the importance of education and self-development. </p>
                  //   <p>Our mission is to build a learning environment for the learners where they can enhance their skillset and full fill their passion. Our aim is to brush up innovation, creativity and personality development in the students.</p>
                  //   <p>Winventive is currently providing services to four main streams. The four main streams are Engineering, Medical, Business Development, Personal Development. </p>
                  //  <p>Students enrolled in Winventive can learn their favorite course, upgrade their skill set, and will be working on different on hand projects.</p>
                  //  <p>Winventive believes that there are multiple ways to empower people.</p></p> }
                  // , {img:that,cont:"That INDIAN DUO",desc:<p>
                  //   <p>'That INDIAN DUO', we echo by the name pronounced. Taking stance as budding entrepreneurs, we chose to innovate into the domain of 'enfotainment', where an ambit of education, information, and entertainment can be felt.</p>
                  //   <p>We started up with a YOU TUBE channel, where we impart our share of knowledge in various regards. We do compile the information by videotaping, in the split stanza of 'enfotainment'. We host interviews with inspirational personae, 'explore the unexplored' by framing travel vlogs, and also create message oriented video content.</p>
                  //   <p>We aim to rise as a Digital Media Company down the timeline, with a motive to help the start-ups and social media content creators to publicize their work through our company. In short, we started out with an intent of knowledge share to every nook and corner and are making way to destine our goal set.</p>
                  // </p>}
                 
                   {
                    img:sf,cont:"Smart Fabrics",desc:<p>
                      Smart fabrics is a technology company headquartered in Warangal, Telangana, that designs, develops and sells smart jackets for safety and security. Smart jackets is a wearable technology product that gets turned into protective electric armour at the switch of a button.
                    </p>

                  }, 
                  // {
                  //   img:new1,cont:"V2W",desc:<p>
                  //   Company will provide trainings on Placement and soft skills. Company will also provide Computer courses like C,C++,JAVA, PYTHON, HTML,CSS etc. We also work on projects regarding Artificial intelligence, Ethical hacking. We provide jobs to unemployes and students in their relevant field. And also we are planning to develop it as a project based company </p>

                  // }, 
                  {
                    img:kd,cont:"Kaam dhaam",desc:<p>
                    <p><h1>Kaam dhaam :</h1>Job Search/business for skilled, semi-skilled, unskilled workers and small businesses within a proximity in regional language.</p>
<p><h1>MISSION:</h1> To be a part of every bodies lives by offering jobs/business for skilled, semi-skilled, unskilled people and small business on one hand and make the lives of others hassle free on the other hand throughout the world.</p>
<p><h1>VISSION:</h1> To be one of the leading companies in the world, admired for its brand, contributing to a better and sustainable world where people can earn something every day.
 </p></p>

                  }, 
                  {img:krushiseva,cont:"KRUSHISEVA",desc:<p><p> KRUSHISEVA lets you buy groceries from your local Kirana store, online! </p>
 
                  <p> To get home delivery of your favorite groceries simply: </p>
                    
                  <p> * Choose your nearby grocery store </p>
                    
                  <p>* Select from a wide variety of products including Dal, Atta, Oil, Ghee, Sugar, Noodles, 
                  Soaps and Shampoos. </p> 
                    
                   <p>* Enjoy home delivery within 24 hours! 
                   Shop anytime, anywhere from the comfort and convenience of your home and support your local kirana stores. Every KRUSHISEVA order is fulfilled from a grocery store in your area! </p>
                    
                  <p>What can you expect from KRUSHISEVA? </p> 
                    
                   <p>* Everything on your Grocery Shopping List: 
                   All your favorite grocery products from local products to national and international brands including Amul, Bisleri, Saffola, Cadbury, Horlicks, Surf Excel, Lifebuoy, Dettol, Vim, Bru, Red Label, Taj Mahal, Haldiram, Kellog’s, Aashirvaad, Tata Salt, Maggi and many more! </p>
                    
                   <p>* Your Trusted, Neighborhood, Local Store Completely Online: 
                   Simple choose your store, place your order and sit back! </p>
                    
                   <p>* Great Offers: 
                   Get great grocery deals including product discounts, exciting coupons, free home delivery* and more! 
                    </p>
                  <p> * Home Delivery: 
                  Get door step delivery of your order within 24 hours! </p>
                    
                   <p>* Fast Checkout: 
                   Pay using Cash on Delivery, UPI, e-wallets and more!</p> 
                   
                   <p>Looking for a grocery store near you? 
                   Trying to do some shopping from your nearby kirana? 
                   Running out of rations/groceries? 
                   Got a craving for chocolates, namkeen, Dairy Milk Silk? 
                   Detergent running low? </p>
                   
                    
                   <p>Stocking up on pulses and chawal for the month? 
                   Urgent Maggi stocking? 
                   Shopping at your local kirana store for years but find online shopping more convenient? Just looking for some groceries online? 
                   Looking for the convenience of Home Delivery? 
                   Searching for household essentials? 
                   Wish someone would deliver the groceries you want, from the store you want? 
                    </p>
                  <p>KRUSHISEVA is the answer to all these questions and more. We would love to serve you 😊 </p> 
                   
                  <p> KRUSHISEVA BUSINESS </p>
                   
                   <p>We are empowering our Partnered Kiranas with the Digital India and with the power of technology we are connecting Farmers with these outlets in order to benefit the farmers pricing issues in our country. </p>
                   
                  <p>The Mission of the KRUSHISEVA is to empower the backbone of India i.e. farmers with power of technology  to connect them with direct consumers through small businesses.</p>  </p> },
                  
                  {
                    img:sun,cont:"Sunrisemitra  Global Technologies",desc:<p>
                   We are the leading service provider in kallur mandal ,khammam and providing Recharge & Bill Payment API to our valuable customers.
Start today for a better future. Start your own mobile recharge business with us. Establishment of higher marketing standard is a key of global market structure.

Every financial standard and life standard of people is always depends on marketing strategy. Impost Money brings you a standard and all new marketing plan in network marketing.</p>

                  }, 
                  ];
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = active.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
      <Top
        imager={vibaS}
        caption={
          "THERE'S NO SHORTAGE OF REMARKABLE IDEAS, WHAT'S MISSING IS THE WILL TO EXECUTE THEM."
        }
        cap1={"PITCH"}
        cap2={"PRONOUNCE"}
        cap3={"PUBLICIZE"}
      />
      <MiddleT
        colorH1={"#231F20"}
        colorB={"#F36600"}
        colorM={"#000"}
        head1={"STARTUP's"}
        head2={"of"}
        head3={"VIBA GET"}
        text={
          "Viba has started with an aim of assisting various startup's approaching us by developing an innovative startup ecosystem among the student community. We provide guidance right from the basics of planning to growing your business. We provide you with a workspace and an opportunity to link up with various industry experts, mentors and advisors for proper guidance."
        }
      />
      <Container max-Width="md">
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            backgroundRepeat: "no-repeat",
            backgrouundPosition: "center",
            backgroundSize: "cover",
            backgroundImage: `url(${back})`,
            padding: "9vh 0vh",
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ThemeProvider theme={theme}>
              <Typography
                variant="h5"
                align="center"
                style={{
                  fontWeight: "bolder",
                  margin: "0vh 0vh 3vh 0vh",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                how we do it
                <Typography
                  variant="h4"
                  align="center"
                  style={{
                    fontWeight: "bolder",
                    color: "#F36600",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  IN VIBA
                </Typography>
              </Typography>
            </ThemeProvider>
          </Grid>
          {info.map((dat, index) => (
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4} className="cardS">
              <Paper
                elevation={7}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "1.6vh 0vh",
                  maxHeight: "100%",
                }}
                className="paperS"
              >
                <img
                  src={dat.img}
                  alt="ASSESSMENT"
                  style={{ height: "30vh", width: "100%" }}
                />
                <ThemeProvider theme={theme}>
                  <Typography
                    variant="h6"
                    align="center"
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "bolder",
                      color: "#000",
                      textTransform: "uppercase",
                      marginTop: "2.1vh",
                    }}
                  >
                    {dat.name}
                  </Typography>
                </ThemeProvider>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ backgroundColor: "#FBFBFD" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          className="LeftT"
          style={{ maxHeigth: "100%" }}
        >
          <ThemeProvider theme={theme}>
            <Typography
              variant="h5"
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
                color: "#000",
              }}
            >
              ACTIVE
              <Typography
                variant="h3"
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "bold",
                  color: "#F36600",
                }}
              >
                STARTUP'S
              </Typography>
            </Typography>
          </ThemeProvider>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={8}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "5vh 0vh",
            width: "100%",
          }}
        >
          <div className="grt">
       
              {active.map((step, index) => (
                <div className="groot"  key={step.label}>
                  
                   <div className="gro" style={{backgroundImage: `url(${step.img})`,backgroundSize:"contain"}}>
                      
                  
                    </div>
                     <Popup contentStyle={{width: "60%",height:"60%",overflowY:"scroll",padding:'2vh 5vh'}}  trigger={<button className="button1"> Know More </button>} modal>
                                
                                {close => (
                                  <div className="modal">
                                    <a className="close" onClick={close}>
                                      &times;
                                    </a>
                                <div className="header" style={{color:"#ff8000"}}> {step.cont}</div>
                                    <div className="content">
                                
                                <p>{step.desc}</p>
                                    </div>
                                    <div className="actions">
                                     
                                      <button
                                        className="button"
                                        style={{padding:"1vh 3vh",fontSize:"3vh"}}
                                        onClick={() => {
                                          console.log("modal closed ");
                                          close();
                                        }}
                                      >
                                          close 
                                      </button> 
                                    </div>
                                  </div>
                                )}
                              
                              </Popup>
               
                </div>
              ))}

        

            
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ backgroundColor: "#FFF" }}
      >
     
        {/* <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={8}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Paper
                elevation={5}
                style={{
                  padding: "0vh 5vh",
                  margin: "3vh",
                  width: "70%",
                  height: "27vh",
                  position: "relative",
                }}
              >
                <Typography
                  variant="h5"
                  align="center"
                  style={{
                    fontWeight: "bolder",
                    maxWidth: "100%",
                    letterSpacing: "1.2px",
                  }}
                  className="textE"
                >
                  ELECTRONOOBS
                </Typography>
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              alignContent="center"
              alignItems="center"
              justify="center"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Paper
                elevation={5}
                style={{
                  padding: "0vh 5vh",
                  margin: "3vh",
                  width: "70%",
                }}
              >
                <img src={krushiseva} height="100%" width="100%" />
              </Paper>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              alignContent="center"
              alignItems="center"
              justify="center"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Paper
                elevation={5}
                style={{
                  padding: "0vh 5vh",
                  margin: "3vh",
                  width: "70%",
                  height: "27vh",
                  position: "relative",
                }}
              >
                <Typography
                  variant="h5"
                  align="center"
                  style={{
                    fontWeight: "bolder",
                    maxWidth: "100%",
                    letterSpacing: "1.2px",
                  }}
                  className="textE"
                >
                  SHEA
                </Typography>
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              alignContent="center"
              alignItems="center"
              justify="center"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Paper
                elevation={5}
                style={{
                  padding: "0vh 5vh",
                  margin: "3vh",
                  width: "70%",
                  height: "27vh",
                  position: "relative",
                }}
              >
                <Typography
                  variant="h5"
                  align="center"
                  style={{
                    fontWeight: "bolder",
                    maxWidth: "100%",
                    letterSpacing: "1.2px",
                  }}
                  className="textE"
                >
                  Keth Sahai
                </Typography>
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              alignContent="center"
              alignItems="center"
              justify="center"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Paper
                elevation={5}
                style={{
                  padding: "0vh 5vh",
                  margin: "3vh",
                  width: "70%",
                  height: "27vh",
                  position: "relative",
                }}
              >
                <Typography
                  variant="h5"
                  align="center"
                  style={{
                    fontWeight: "bolder",
                    maxWidth: "100%",
                    letterSpacing: "1.2px",
                  }}
                  className="textE"
                >
                  Triple Entete
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </Grid>
  );
}
