import React from "react";
import "./layout.css";
import { Link } from "react-router-dom";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import BubbleChartRoundedIcon from "@material-ui/icons/BubbleChartRounded";
import BookRoundedIcon from "@material-ui/icons/BookRounded";
import EventRoundedIcon from "@material-ui/icons/EventRounded";
import ContactsRoundedIcon from "@material-ui/icons/ContactsRounded";
import EmojiObjectsRoundedIcon from "@material-ui/icons/EmojiObjectsRounded";
import PeopleOutlineRoundedIcon from "@material-ui/icons/PeopleOutlineRounded";
import viba from "./viba.png";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "sticky",
    top: 43,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5vh",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  items: {
    marginBottom: 9,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export default function NavMain() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MenuList>
        <MenuItem className={classes.items}>
          <Avatar
            variant="square"
            alt="VIBA"
            src={viba}
            className={classes.large}
          />
        </MenuItem>
        <Link
          exact
          to="/"
          style={{
            textDecoration: "none",
            color: "#000000",
          }}
        >
          <MenuItem className={classes.items}>
            <HomeRoundedIcon style={{ textAlign: "center" }} fontSize="large" />

            <Typography
              style={{
                fontWeight: "bold",
                color: "#4B0082",
                fontSize: "16px",
                paddingTop: "1.2vh",
              }}
              align="center"
              noWrap
            >
              Home
            </Typography>
          </MenuItem>
        </Link>
        <Link
          to="/startups"
          style={{
            color: "#000000",
            textDecoration: "none",
          }}
        >
          <MenuItem className={classes.items}>
            <EmojiObjectsRoundedIcon
              style={{ textAlign: "center" }}
              fontSize="large"
            />
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                paddingTop: "1.2vh",
              }}
              align="center"
              noWrap
            >
              StartUp's
            </Typography>
          </MenuItem>
        </Link>
        <Link
          to="/events"
          style={{
            color: "#000000",
            textDecoration: "none",
          }}
        >
          <MenuItem className={classes.items}>
            <EventRoundedIcon
              style={{ textAlign: "center" }}
              fontSize="large"
            />
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                paddingTop: "1.2vh",
              }}
              align="center"
              noWrap
            >
              Events
            </Typography>
          </MenuItem>
        </Link>
        <Link
          to="/about"
          style={{
            color: "#000000",
            textDecoration: "none",
          }}
        >
          <MenuItem className={classes.items}>
            <BubbleChartRoundedIcon
              style={{ textAlign: "center" }}
              fontSize="large"
            />
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                paddingTop: "1.2vh",
              }}
              align="center"
              noWrap
            >
              About Us
            </Typography>
          </MenuItem>
        </Link>
        <Link
          to="/team"
          style={{
            color: "#000000",
            textDecoration: "none",
          }}
        >
          <MenuItem className={classes.items}>
            <PeopleOutlineRoundedIcon
              style={{ textAlign: "center" }}
              fontSize="large"
            />
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                paddingTop: "1.2vh",
              }}
              align="center"
              noWrap
            >
              Our Team
            </Typography>
          </MenuItem>
        </Link>
        <Link
          to="/blog"
          style={{
            color: "#000000",
            textDecoration: "none",
          }}
        >
          <MenuItem className={classes.items}>
            <BookRoundedIcon style={{ textAlign: "center" }} fontSize="large" />
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                paddingTop: "1.2vh",
              }}
              align="center"
              noWrap
            >
              Blog
            </Typography>
          </MenuItem>
        </Link>
        {/* <MenuItem className={classes.items}>
          <ContactsRoundedIcon
            style={{ textAlign: "center" }}
            fontSize="large"
          />
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              paddingTop: "1.2vh",
            }}
            align="center"
            noWrap
          >
            Contact Us
          </Typography>
        </MenuItem> */}
      </MenuList>
    </div>
  );
}
