import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "../layout/layout.css";
import Typography from "@material-ui/core/Typography";
import { Grid, Hidden, Paper, Button } from "@material-ui/core";
import Papers from "../utils/papers";
import avasyu from "../layout/images/avasyu.png";
import krushiseva from "../layout/images/krushiseva.png";
import sam from "../layout/images/sam.png";
import that from "../layout/images/that.png";
import kart from "../layout/images/ups.png";
import MetaTags from 'react-meta-tags';
import Men from "../men/mentolop/Mentolo";
import MailRoundedIcon from "@material-ui/icons/MailRounded";
import PhoneRoundedIcon from "@material-ui/icons/PhoneRounded";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import MiddleT from "../Comps/MiddleT";
import Top from "../Comps/Top";
import HomePage from "../HomePage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    style: {
      marginTop: 10,
      borderRadius: 14,
      width: theme.spacing(41),
      padding: 16,
      backgroundColor: "#F5F8FA",
      textAlign: "center",
      position: "sticky",
      top: "23vh",
    },
  },
  title: {
    marginTop: 10,
    borderRadius: 14,
    width: theme.spacing(41),
    padding: 16,
    backgroundColor: "#F5F8FA",
    textAlign: "center",
    position: "sticky",
    top: "8vh",
  },
}));
export default function Home() {
  const classes = useStyles();
  const active = [avasyu, sam, kart, that];
  const idea = [krushiseva];
  const [id, setId] = React.useState("live");
  const handleIdU = () => {
    setId("upcg");
  };
  const handleIdL = () => {
    setId("live");
  };
  const handleIdP = () => {
    setId("past");
  };

  return (
    <div>
         <MetaTags>
            <title>Vaagdevi Incubation and Business Accelerator</title>
            <meta name="description" content="VIBA, well known as 'Vaagdevi Incubation and Business Accelerator'
      is an organization with entrepreneurial interface. It is established
      as a registered society back in 2019 with an agenda of fostering
      entrepreneurs and flourish an aura of entrepreneurship among
      students in technical sphere of education." />
           
          </MetaTags>
      <HomePage />
    </div>
  );
}
