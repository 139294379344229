import React from "react";
import "./evee.css";
import { Grid, Button, Typography } from "@material-ui/core";

import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

export default function EveCard(props) {
  return (
    <>
    {/*  <div className="eve-main">
      <div className="eve-sub">
        <div className="left-evee">
          <div className="left-items">
            <h1>{props.he1}</h1>
            <h1>{props.hetitle}</h1>
            <p>{props.hepara}</p>
          </div>
        </div>
        <div className="right-evee">
          <div className="right-divs">
            <div className="mudu1">
              <div
                className="img1 o1"
                style={{ backgroundImage: `url(${props.backhe1})` }}
              ></div>
              <div
                className="img1 o2"
                style={{ backgroundImage: `url(${props.backhe2})` }}
              ></div>
              <div
                className="img1 o3"
                style={{ backgroundImage: `url(${props.backhe3})` }}
              ></div>
            </div>
            <div className="mudu2">
              <div
                className="img1 o4"
                style={{ backgroundImage: `url(${props.backhe4})` }}
              ></div>
              <div
                className="img1 o5"
                style={{ backgroundImage: `url(${props.backhe5})` }}
              ></div>
              <div
                className="img1 o6"
                style={{ backgroundImage: `url(${props.backhe6})` }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>   */}
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      style={{ backgroundColor: "#F36600" }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        style={{
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: "5vh",
        }}
      >
        <ThemeProvider theme={theme}>
          <Typography
            align="left"
            variant="h3"
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "bold",
              color: "#FFF",
              padding: "3vh 0vh",
            }}
          >
            ANTHARPRERANA
          </Typography>
          <Typography
            variant="subtitle1"
            alugn="left"
            style={{
              fontFamily: "Montserrat, sans-serif",
              letterSpacing: "1.2px",
              wordSpacing: "3px",
              color: "#FFF",
              paddingBottom: "1.7vh",
              textAlign: "left",
            }}
          >
            This competition is the final round in Mission Antharprerana in
            which students were combined as the participants from all the 3
            rounds of this event and the top 3 finalists are sorted out from all
            the teams who secured the knowledge on networking, group discussion,
            presentation, project ideation etc.
          </Typography>
        </ThemeProvider>
      </Grid>
    </Grid>


  </> 

);
}