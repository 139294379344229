import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import fresh from "../Events/fresh.jpeg";
import hack from "../Events/hack.jpeg";
import rachana1 from "../Events/rachana1.jpeg";
import sankalp from "../Events/sankalp.jpeg";
import anugrahak from "../Events/anugrahak.jpeg";
import kala from "../Events/kala.jpeg";
import matlab from "../Events/matlab.jpeg";
import vtalk from "../Events/vtalk.jpeg";
import sams from "../Events/sams.jpeg";
import { Link } from "react-router-dom";
import enter from "../Events/enter.jpeg";
import tech from "../Events/tech.jpeg";
import fresh2 from "../Events/fresh3.jpeg";
import quizz1 from "../Events/quizz1.jpeg";
import quizz2 from "../Events/quizz2.jpeg";
import cloud from "../Events/cloud.jpeg";
import innova from "../Events/innova.jpeg";
import budd from "../Events/budd.jpeg";
import holo from "../Events/holo.jpeg";
import anter from "../Events/anter.jpeg";
import budd2 from "../Events/budd2.jpeg";
import vibav from "../Events/vibav.jpeg";
import space from "../Events/space.jpeg";
import Top from "../../Comps/Top";
import MiddleT from "../../Comps/MiddleT";
import { Grid, Typography, Paper } from "@material-ui/core";
import vibaE from "../Events/vibaE.png";
import Fotter from "../footerg/Fotter";
import Evee from "./evee/Evee";
import pho from "../Events/s4.jpeg"
import pho1 from "../Events/s3.jpeg"
import pho2 from "../Events/eventp.jpeg"
import pho3 from "../Events/main.jpeg"
import pho4 from "../Events/nari.jpeg"
import pho5 from "../Events/randd.jpeg"
import pho06 from "../Events/civil.jpg"
import pho07 from "../Events/bramhakumaris.jpg"



import "./events.css";

const Events1 = () => {
  
  const core = [
    // {
    //   date: "07",
    //   month: "Dec",
    //   evebtdesc: "this is an event",
    //   name: " HACK FOR NEED 2.0",
    //   img:hackforneed,
    // },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: " PEACEFUL MIND FOR PEACEFUL LIFE",
      img:pho07,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "Autodesk Revit Architecture",
      img:pho06,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "R&D at Vaagdevi Colleges Organised by VIBA and RICH",
      img:pho5,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "Freshmen Ideathon 2.0",
      img:fresh2,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "VIBA NARI",
      img:pho4,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: " NASA SPACE APPS CHALLENGE 2020",
      img:pho3,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "MOU between VIBA and RICH",
      img:pho2,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "SPACE TALK",
      img:space,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "VIBA VIDYA",
      img: vibav,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "VIBA BHARATH",
      img: pho,
    },


    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "Dialogue with Budding Engineers",
      img: budd,
    },
    
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "VIBA TALENT HEIST",
      img: pho1,
    },
  
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "Dialogue with Budding Engineers 2",
      img: budd2,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "Call for Institute Innovation Fellows",
      img: innova,
    },
   
   
  ];
  const side = [
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "Mission AntharPrerana",
      img: anter,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "Student Holistic Relations",
      img: holo,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "Webinar on Matlab",
      img: matlab,
    },
   
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "Hack For The Need",
      img: hack,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "Rachana Blogathon",
      img: rachana1,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "Sankalp Codeathon",
      img: sankalp,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "Anugrahak Customer-on",
      img: anugrahak,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "Kalakshetra",
      img: kala,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "V-Talks",
      img: vtalk,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "Samstithi",
      img: sams,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "Entrepreneurship 101 By our CEO",
      img: enter,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "Webinar 2 By our CEO",
      img: tech,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "COVID Awareness",
      img: quizz1,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "Entrepreneurship Quiz",
      img: quizz2,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "Workshop on Cloud Computation",
      img: cloud,
    },
    {
      date: "07",
      month: "Dec",
      evebtdesc: "this is an event",
      name: "Freshmen Ideathon",
      img: fresh,
    },
  
  ];

  return (
    <>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="supermaino"
        style={{ backgroundColor: "#FFF" }}
      >
        <Top
          imager={vibaE}
          caption={
            "If you always do what you always did, you will always get what you always got"
          }
          cap1={"Participate"}
          cap2={"Prove"}
          cap3={"Prosper"}
        />
        <MiddleT
          color={"#FFF"}
          colorH1={"#231F20"}
          colorB={"#F36600"}
          colorM={"#000"}
          head1={"What are"}
          head2={"EVENTS FOR"}
          text={
            "Events are an opportunity to convene a group of people to work or participate in a particular theme or purpose.VIBA works with the aim of developing competitive ecosystem among the student community, hence looking forward for more and more exposure for mutual utility"
          }
        />
        <MiddleT
          color={"#F36600"}
          colorH1={"#FFF"}
          colorB={"#FFF"}
          colorM={"#FFF"}
          head1={"COMPANIES GET"}
          text={
            "Companies get a chance to interact and work with young minds, exploring diversity. The youth are bold and fearless workers, their innovative ideas will work as a boon for the company."
          }
        />
        <MiddleT
          color={"#FFF"}
          colorH1={"#231F20"}
          colorB={"#F36600"}
          colorM={"#000"}
          head1={"STUDENTS GET"}
          text={
            "We conduct events that are beneficial to students. Students participating in events can know the market requirements, demands in the market, and how they can proceed with their ideas. Once the students start participating in such events they get used to working in groups"
          }
        />
            <Evee />
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            maxHeight: "100%",
            backgroundColor: "#F2F6FA",
            paddingBottom: "9vh",
          }}
        >
          <div className="heading-event">
            <h1>RECENT EVENTS</h1>
          </div>
          <div className="grid-4">
            <div className="sub-grid-4">
            {core.map((core, index) => (


              <div className="mama">

<div className="sub-mama" /* style={{backgroundImage: `url(${side.img})`}} */ >
                <div className="mo" >
                 <img src={core.img} />
                 <div className="dere">
                   {/* <h1>EDUTECH</h1> */}
                   <div className="pa">
                   <h2>{core.name}</h2>
        
                     </div>
           
            {/* <div className="link12">
            <h3>knowmore</h3>
            <FontAwesomeIcon icon={faLink} size="3x" className="bow" />
              </div>
             */}
                   </div>
                   </div>
              </div>

              </div>





            ))}
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{height:"100%"}}
        >
          <div className="heading-event">
            <h1>PAST EVENTS</h1>
          </div>
          <div className="grid-4">
          <div className="sub-grid-4">
            {side.map((side, index) => (

              
              <div className="mama">

              <div className="sub-mama" /* style={{backgroundImage: `url(${side.img})`}} */ >
                <div className="mo">
                 <img src={side.img} />
                 <div className="dere">
                   {/* <h1>EDUTECH</h1> */}
                   <div className="pa">
                   <h2>{side.name}</h2>
        
                     </div>
           
            {/* <div className="link12">
            <h3>knowmore</h3>
            <FontAwesomeIcon icon={faLink} size="3x" className="bow" />
              </div> */}
            
                   </div>
                   </div>
              </div>

              </div>


            ))}
                </div>
          </div>
        </Grid>
      </Grid>

    </>
  );
};

export default Events1;
