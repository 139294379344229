import React, { Component } from "react";
import Slider from "react-slick";
import "./mentolo.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import Mencard from "./Mencard";
import img from "../images/a.jpg"
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none"}}
      onClick={onClick}
    />
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none"}}
      onClick={onClick}
    />
  );
}
export default class Mentolo extends Component {
  render() {
    const settings = {
     
      autoplay:true,
    
      fade:true,
      infinite: true,
     dots:false,
      slidesToShow: 1,
      speed: 900,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };
    return (
     
        
        <Slider {...settings}>
          <div className="mkmk">
            <Mencard paratest={"Testimonial Slider is a fully Responsive & mobile friendly WordPress plugin to manage your client Testimonials.It had Slider & Grid layouts. You can create unlimited shortcode. It is fully flexible to use you can control how many display per row and primary colors from shortcode generator."} nametest={"Dr.M.K Kaushik"} proftest="CEO VIBA" back={img} />
          </div>
          <div className="mkmk">
          <Mencard paratest={"Testimonial Slider is a fully Responsive & mobile friendly WordPress plugin to manage your client Testimonials.It had Slider & Grid layouts. You can create unlimited shortcode. It is fully flexible to use you can control how many display per row and primary colors from shortcode generator."} nametest={"Dr.M.K Kaushik"} proftest="CEO VIBA" back={img}  />
          </div>
          <div className="mkmk">
          <Mencard paratest={"Testimonial Slider is a fully Responsive & mobile friendly WordPress plugin to manage your client Testimonials.It had Slider & Grid layouts. You can create unlimited shortcode. It is fully flexible to use you can control how many display per row and primary colors from shortcode generator."} nametest={"Dr.M.K Kaushik"} proftest="CEO VIBA" back={img}  />
          </div>
           
        </Slider>
      
    );
  }
}