import React, {
  useCallback,
  useState,
  Children,
  useContext,
  useEffect,
} from "react";
import { Grid, Paper, Button, Typography, Hidden } from "@material-ui/core";
import "./blog.css";
import BlogCardM from "./blogCardM";
import BlogCardSub from "./blogCardSub";
import BlogWrite from "./blogWrite";
import base from "../../../config/FbConfig";
import { AuthContext } from "../../../config/auth";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

export default function BlogMain() {
  const [latest, setLatest] = useState([]);
  const [chandra, setChandra] = useState([]);
  const [sandy, setSandy] = useState([]);
  const { userId, userName } = useContext(AuthContext);

  useEffect(() => {
    const unsubscribe = base
      .firestore()
      .collection("LatestBlogs")
      .onSnapshot((snapshot) => {
        const newData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLatest(newData);
        /*         setLoading(false);
         */
      });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    const unsubscribe = base
      .firestore()
      .collection("VibaBlog")
      .doc("lM7vljc6PqTEOsRoZol2Ixfl7O42")
      .collection("Blogs")
      .onSnapshot((snapshot) => {
        const newData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setChandra(newData);
        /*         setLoading(false);
         */
      });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    const unsubscribe = base
      .firestore()
      .collection("VibaBlog")
      .doc("K4PpcMsBZBYPVVYi2Fm97kNxqOC2")
      .collection("Blogs")
      .onSnapshot((snapshot) => {
        const newData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSandy(newData);
        /*         setLoading(false);
         */
      });
    return () => unsubscribe();
  }, []);
  //console.log(Object.values(latest));

  return (
    <div style={{ marginBottom: "3vh" }}>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ThemeProvider theme={theme}>
            <Typography
              variant="h2"
              align="left"
              style={{
                color: "#F36600",
                fontWeight: "700",
                letterSpacing: "1.2px",
                fontFamily: "Montserrat, sans-serif",
                margin: "1.6vh 3vh",
              }}
            >
              VIBA's<span className="blog">BLOG</span>
            </Typography>
          </ThemeProvider>
          <div
            style={{
              margin: "0vh 3vh 3vh 3vh",
              height: "0.3vh",
              maxWidth: "100%",
              backgroundColor: "#000",
            }}
          ></div>
        </Grid>
      </Grid>
      {userId ? (
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Paper
              elevation={7}
              style={{
                width: "81%",
                margin: "5vh auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "5vh 0vh",
              }}
            >
              <ThemeProvider theme={theme}>
                <Typography
                  variant="h4"
                  align="center"
                  style={{
                    color: "#000",
                    fontWeight: "450",
                    letterSpacing: "1.2px",
                    fontFamily: "Montserrat, sans-serif",
                    margin: "3vh 0vh 1vh 0vh",
                    textTransform: "uppercase",
                  }}
                >
                  Create your own BLOG
                </Typography>
              </ThemeProvider>
              <div
                style={{
                  margin: "0vh 3vh 3vh 3vh",
                  height: "0.3vh",
                  maxWidth: "100%",
                  backgroundColor: "#000",
                }}
              ></div>
              <BlogWrite />
            </Paper>
          </Grid>
        </Grid>
      ) : null}
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ width: "91%", margin: "0vh auto" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "3vh 0vh",
          }}
        >
          <ThemeProvider theme={theme}>
            <Typography
              variant="h4"
              align="left"
              color="textPrimary"
              style={{
                fontWeight: "450",
                letterSpacing: "1.2px",
                fontFamily: "Montserrat, sans-serif",
                padding: "0vh 5vh 2vh 0vh",
                borderBottom: "1.2px solid black",
              }}
            >
              Latest Blogs
            </Typography>
          </ThemeProvider>
          <Hidden xsDown>
            <Link
              to={{
                pathname: "/moreBlogs",
                state: { data: latest, name: "Latest Blogs" },
              }}
              style={{ textDecoration: "none" }}
            >
              <Button variant="outlined" style={{ color: "#F36600" }}>
                More
              </Button>
            </Link>
          </Hidden>
        </Grid>

        {latest.slice(0, 4).map((ter) => (
          
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={3}
            xl={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "3vh",
            }}
          >
            <BlogCardM
              author={ter.id}
              img={ter.img}
              title={ter.title}
              intro={ter.Introduction}
              time={ter.postedTime}
              matter={ter.matter}
            />
          </Grid>
        ))}
        <Hidden smUp>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link
              to={{
                pathname: "/moreBlogs",
                state: { data: latest, name: "Latest Blogs" },
              }}
              style={{ textDecoration: "none" }}
            >
              <Button variant="outlined" style={{ color: "#F36600" }}>
                More
              </Button>
            </Link>
          </div>
        </Hidden>
      </Grid>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ width: "91%", margin: "0vh auto" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "7vh 0vh 4.5vh 0vh",
          }}
        >
          <ThemeProvider theme={theme}>
            <Typography
              variant="h4"
              align="left"
              color="textPrimary"
              style={{
                fontWeight: "450",
                letterSpacing: "1.2px",
                fontFamily: "Montserrat, sans-serif",
                padding: "0vh 5vh 2vh 0vh",
                borderBottom: "1.2px solid black",
              }}
            >
              By Dr. A. Chandramouli
            </Typography>
          </ThemeProvider>
          <Hidden xsDown>
            <Link
              to={{
                pathname: "/moreBlogs",
                state: {
                  id: "lM7vljc6PqTEOsRoZol2Ixfl7O42",
                  data: chandra,
                  name: "By Dr. A. Chandramouli",
                },
              }}
              style={{ textDecoration: "none" }}
            >
              <Button variant="outlined" style={{ color: "#F36600" }}>
                More
              </Button>
            </Link>
          </Hidden>
        </Grid>

        {chandra.slice(0, 4).map((ter) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={3}
            xl={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "3vh",
            }}
          >
            <BlogCardSub
              author={"lM7vljc6PqTEOsRoZol2Ixfl7O42"}
              img={ter.img}
              title={ter.title}
              intro={ter.Introduction}
              time={ter.postedTime}
              matter={ter.matter}
            />
          </Grid>
        ))}
        <Hidden smUp>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link
              to={{
                pathname: "/moreBlogs",
                state: {
                  id: "lM7vljc6PqTEOsRoZol2Ixfl7O42",
                  data: chandra,
                  name: "By Dr. A. Chandramouli",
                },
              }}
              style={{ textDecoration: "none" }}
            >
              <Button variant="outlined" style={{ color: "#F36600" }}>
                More
              </Button>
            </Link>
          </div>
        </Hidden>
      </Grid>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ width: "91%", margin: "0vh auto" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "7vh 0vh 4.5vh 0vh",
          }}
        >
          <ThemeProvider theme={theme}>
            <Typography
              variant="h4"
              align="left"
              color="textPrimary"
              style={{
                fontWeight: "450",
                letterSpacing: "1.2px",
                fontFamily: "Montserrat, sans-serif",
                padding: "0vh 5vh 2vh 0vh",
                borderBottom: "1.2px solid black",
              }}
            >
              By Sandhya Pinna
            </Typography>
          </ThemeProvider>
          <Hidden xsDown>
            <Link
              to={{
                pathname: "/moreBlogs",
                state: {
                  data: sandy,
                  id: "K4PpcMsBZBYPVVYi2Fm97kNxqOC2",
                  name: "Sandhya Pinna",
                },
              }}
              style={{ textDecoration: "none" }}
            >
              <Button variant="outlined" style={{ color: "#F36600" }}>
                More
              </Button>
            </Link>
          </Hidden>
        </Grid>

        {sandy.slice(0, 4).map((ter) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={3}
            xl={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "3vh",
            }}
          >
            <BlogCardSub
              author="K4PpcMsBZBYPVVYi2Fm97kNxqOC2"
              img={ter.img}
              title={ter.title}
              intro={ter.Introduction}
              time={ter.postedTime}
              matter={ter.matter}
            />
          </Grid>
        ))}
        <Hidden smUp>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link
              to={{
                pathname: "/moreBlogs",
                state: {
                  data: sandy,
                  id: "K4PpcMsBZBYPVVYi2Fm97kNxqOC2",
                  name: "Sandhya Pinna",
                },
              }}
              style={{ textDecoration: "none" }}
            >
              <Button variant="outlined" style={{ color: "#F36600" }}>
                More
              </Button>
            </Link>
          </div>
        </Hidden>
      </Grid>
    </div>
  );
}
