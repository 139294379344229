import React, { useContext } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import PeopleOutlineRoundedIcon from "@material-ui/icons/PeopleOutlineRounded";
import EmojiObjectsRoundedIcon from "@material-ui/icons/EmojiObjectsRounded";
import EventRoundedIcon from "@material-ui/icons/EventRounded";
import BubbleChartRoundedIcon from "@material-ui/icons/BubbleChartRounded";
import BookRoundedIcon from "@material-ui/icons/BookRounded";
import HowToRegRoundedIcon from "@material-ui/icons/HowToRegRounded";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import MeetingRoomRoundedIcon from "@material-ui/icons/MeetingRoomRounded";
import MailRoundedIcon from "@material-ui/icons/MailRounded";
import PhoneRoundedIcon from "@material-ui/icons/PhoneRounded";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
import { AuthContext } from "../../config/auth";
import base from "../../config/FbConfig";
import "./layout.css";

const drawerWidth = "100%";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function ResponsiveDrawer(props) {
  const { userId, userName } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const open = Boolean(anchorEl);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const drawer = (
    <div style={{ backgroundColor: "#F36600" }}>
      <div className={classes.toolbar} />
      <div className="navBars">
        <Typography
          variant="h6"
          align="center"
          style={{ fontWeight: "bolder", margin: "0.5vh", color: "#FFF" }}
        >
          Hello...!
        </Typography>
        <Typography
          wrap
          variant="h5"
          align="center"
          style={{
            fontWeight: "bolder",
            margin: "0.9vh",
            maxWidth: "100%",
            letterSpacing: "1.2px",
            color: "#FFF",
          }}
        >
          {userName}
        </Typography>
      </div>
      <Divider style={{ margin: "0vh 0.9vh" }} />
      <div style={{ padding: "0vh 0vh 0vh 3vh" }}>
        <List className="navBars">
          <Link to="/" style={{ textDecoration: "none" }}>
            <ListItem className="navBars">
              <li onClick={() => setMobileOpen(!mobileOpen)}>
                <HomeRoundedIcon style={{ color: "#FFF" }} />
                <Typography
                  variant="h6"
                  style={{
                    letterSpacing: "0.5px",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bolder",
                    margin: "0vh 1.8vh",
                    color: "#FFF",
                  }}
                >
                  Home
                </Typography>
              </li>
            </ListItem>
          </Link>
          <div
            style={{
              height: "0.25vh",
              width: "79%",
              backgroundColor: "#FFF",
            }}
          ></div>
          <Link to="/startups" style={{ textDecoration: "none" }}>
            <ListItem className="navBars">
              <li onClick={() => setMobileOpen(!mobileOpen)}>
                <EmojiObjectsRoundedIcon style={{ color: "#FFF" }} />
                <Typography
                  variant="h6"
                  style={{
                    letterSpacing: "0.5px",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bolder",
                    margin: "0vh 1.8vh",
                    color: "#FFF",
                  }}
                >
                  Start Up's
                </Typography>
              </li>
            </ListItem>
          </Link>
          <div
            style={{
              height: "0.25vh",
              width: "79%",
              backgroundColor: "#FFF",
            }}
          ></div>
          <Link to="/events" style={{ textDecoration: "none" }}>
            <ListItem className="navBars">
              <li onClick={() => setMobileOpen(!mobileOpen)}>
                <EventRoundedIcon style={{ color: "#FFF" }} />
                <Typography
                  variant="h6"
                  style={{
                    letterSpacing: "0.5px",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bolder",
                    margin: "0vh 1.8vh",
                    color: "#FFF",
                  }}
                >
                  Events
                </Typography>
              </li>
            </ListItem>
          </Link>
          <div
            style={{
              height: "0.25vh",
              width: "79%",
              backgroundColor: "#FFF",
            }}
          ></div>
          <Link to="/about" style={{ textDecoration: "none" }}>
            <ListItem className="navBars">
              <li onClick={() => setMobileOpen(!mobileOpen)}>
                <BubbleChartRoundedIcon style={{ color: "#FFF" }} />
                <Typography
                  variant="h6"
                  style={{
                    letterSpacing: "0.5px",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bolder",
                    margin: "0vh 1.8vh",
                    color: "#FFF",
                  }}
                >
                  About Us
                </Typography>
              </li>
            </ListItem>
          </Link>
          <div
            style={{
              height: "0.25vh",
              width: "79%",
              backgroundColor: "#FFF",
            }}
          ></div>
          <Link to="/team" style={{ textDecoration: "none" }}>
            <ListItem className="navBars">
              <li onClick={() => setMobileOpen(!mobileOpen)}>
                <PeopleOutlineRoundedIcon style={{ color: "#FFF" }} />
                <Typography
                  variant="h6"
                  style={{
                    letterSpacing: "0.5px",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bolder",
                    margin: "0vh 1.8vh",
                    color: "#FFF",
                  }}
                >
                  Our Team
                </Typography>
              </li>
            </ListItem>
          </Link>
          <div
            style={{
              height: "0.25vh",
              width: "79%",
              backgroundColor: "#FFF",
            }}
          ></div>
          <Link to="/blog" style={{ textDecoration: "none" }}>
            <ListItem className="navBars">
              <li onClick={() => setMobileOpen(!mobileOpen)}>
                <BookRoundedIcon style={{ color: "#FFF" }} />
                <Typography
                  variant="h6"
                  style={{
                    letterSpacing: "0.5px",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bolder",
                    margin: "0vh 1.8vh",
                    color: "#FFF",
                  }}
                >
                  Blog
                </Typography>
              </li>
            </ListItem>
          </Link>

          <div
            style={{
              height: "0.25vh",
              width: "79%",
              backgroundColor: "#FFF",
            }}
          ></div>
          {userId == null ? (
            <>
              <Link to="/login" style={{ textDecoration: "none" }}>
                <ListItem className="navBars">
                  <li onClick={() => setMobileOpen(!mobileOpen)}>
                    <HowToRegRoundedIcon style={{ color: "#FFF" }} />
                    <Typography
                      variant="h6"
                      style={{
                        letterSpacing: "0.5px",
                        fontFamily: "Montserrat, sans-serif",
                        fontWeight: "bolder",
                        margin: "0vh 1.8vh",
                        color: "#FFF",
                      }}
                    >
                      Sign In
                    </Typography>
                  </li>
                </ListItem>
              </Link>
              <div
                style={{
                  height: "0.25vh",
                  width: "79%",
                  backgroundColor: "#FFF",
                }}
              ></div>
              {/* <Link to="/register" style={{ textDecoration: "none" }}>
                <ListItem className="navBars">
                  <li onClick={() => setMobileOpen(!mobileOpen)}>
                    <ExitToAppRoundedIcon style={{ color: "#FFF" }} />
                    <Typography
                      variant="h6"
                      style={{
                        letterSpacing: "0.5px",
                        fontFamily: "Montserrat, sans-serif",
                        fontWeight: "bolder",
                        margin: "0vh 1.8vh",
                        color: "#FFF",
                      }}
                    >
                      Sign Up
                    </Typography>
                  </li>
                </ListItem>
              </Link> */}
            </>
          ) : (
            <ListItem className="navBars">
              <li
                onClick={() => {
                  setMobileOpen(!mobileOpen);
                  base.auth().signOut();
                  history.push("/");
                }}
              >
                <MeetingRoomRoundedIcon style={{ color: "#FFF" }} />
                <Typography
                  variant="h6"
                  style={{
                    letterSpacing: "0.5px",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bolder",
                    margin: "0vh 1.8vh",
                    color: "#FFF",
                  }}
                >
                  Log Out
                </Typography>
              </li>
            </ListItem>
          )}
        </List>
      </div>
      <div className="topCons">
        <Typography
          variant="h5"
          align="center"
          style={{
            letterSpacing: "0.5px",
            fontFamily: "Montserrat, sans-serif",
            fontWeight: "bolder",
            margin: "2vh 0vh 2vh 0vh",
            color: "#FFF",
          }}
        >
          Contact us
        </Typography>
        <ul>
          <li>
            <PhoneRoundedIcon style={{ fontSize: "3vh", color: "#FFF" }} />
            <span>: 9542117888</span>
          </li>
          <li>
            <MailRoundedIcon style={{ fontSize: "3vh", color: "#FFF" }} />
            <span>: vibafoundation@gmail.com</span>
          </li>
        </ul>
      </div>
      <div className={classes.toolbar} />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="relative"
        style={{ backgroundColor: "#F36600" }}
      >
        <Toolbar
          style={{
            display: "flex",
            maxWidth: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "1.8vh 2vh",
          }}
        >
          <Link to="/" style={{ textDecoration: "none" }}>
            <Hidden lgUp>
              <Hidden xsDown>
                <Typography
                  variant="h5"
                  align="center"
                  style={{
                    color: "#FFF",
                    fontWeight: "bolder",
                    letterSpacing: "1.2px",
                  }}
                >
                  Vaagdevi Incubation And Business Accelerator
                </Typography>
              </Hidden>
            </Hidden>
            <Hidden smUp>
              <Typography
                variant="h6"
                align="center"
                style={{
                  color: "#FFF",
                  fontWeight: "bolder",
                  letterSpacing: "1.2px",
                }}
              >
                {userName}
              </Typography>
            </Hidden>
          </Link>
          <Hidden mdDown>
            <Link
              to="/"
              style={{
                textDecoration: "none",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <HomeRoundedIcon style={{ color: "#FFF" }} />
              <Typography
                variant="h6"
                style={{
                  letterSpacing: "0.5px",
                  fontFamily: "Montserrat, sans-serif",
                  color: "#FFF",
                  fontWeight: "bolder",
                  margin: "0vh 1vh",
                }}
              >
                Home
              </Typography>
            </Link>

            <Link
              to="/startups"
              style={{
                textDecoration: "none",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <EmojiObjectsRoundedIcon style={{ color: "#FFF" }} />
              <Typography
                variant="h6"
                style={{
                  letterSpacing: "0.5px",
                  fontFamily: "Montserrat, sans-serif",
                  margin: "0vh 1vh",
                  fontWeight: "bolder",
                  color: "#FFF",
                }}
              >
                Start Up's
              </Typography>
            </Link>
            <Link
              to="/events"
              style={{
                textDecoration: "none",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <EventRoundedIcon style={{ color: "#FFF" }} />
              <Typography
                variant="h6"
                style={{
                  letterSpacing: "0.5px",
                  fontFamily: "Montserrat, sans-serif",
                  margin: "0vh 1vh",
                  fontWeight: "bolder",
                  color: "#FFF",
                }}
              >
                Events
              </Typography>
            </Link>
            <Link
              to="/about"
              style={{
                textDecoration: "none",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BubbleChartRoundedIcon style={{ color: "#FFF" }} />
              <Typography
                variant="h6"
                style={{
                  letterSpacing: "0.5px",
                  fontFamily: "Montserrat, sans-serif",
                  margin: "0vh 1vh",
                  fontWeight: "bolder",
                  color: "#FFF",
                }}
              >
                About Us
              </Typography>
            </Link>

            <Link
              to="/team"
              style={{
                textDecoration: "none",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PeopleOutlineRoundedIcon style={{ color: "#FFF" }} />
              <Typography
                variant="h6"
                style={{
                  letterSpacing: "0.5px",
                  fontFamily: "Montserrat, sans-serif",
                  margin: "0vh 1vh",
                  fontWeight: "bolder",
                  color: "#FFF",
                }}
              >
                Our Team
              </Typography>
            </Link>
            <Link
              to="/blog"
              style={{
                textDecoration: "none",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BookRoundedIcon style={{ color: "#FFF" }} />
              <Typography
                variant="h6"
                style={{
                  letterSpacing: "0.5px",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "bolder",
                  margin: "0 1vh",
                  color: "#FFF",
                }}
              >
                Blog
              </Typography>
            </Link>
          </Hidden>

          <div>
            <AuthContext.Consumer>
              {(context) =>
                context.userId == null ? (
                  <>
                    <Link to="/login" style={{ textDecoration: "none" }}>
                      <Button
                        variant="outlined"
                        color="inherit"
                        size="small"
                        style={{
                          color: "#F36600",
                          backgroundColor: "#FFF",
                          margin: "auto 3vh ",
                          fontWeight: "700",
                        }}
                      >
                        Sign In
                      </Button>
                    </Link>
                   {/*  <Link to="/register" style={{ textDecoration: "none" }}>
                      <Button
                        variant="outlined"
                        color="inherit"
                        size="small"
                        style={{
                          color: "#F36600",
                          backgroundColor: "#FFF",
                          margin: "auto 3vh",
                          fontWeight: "700",
                        }}
                      >
                        Sign Up
                      </Button>
                    </Link> */}
                  </>
                ) : (
                  <>
                    <IconButton
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color="inherit"
                      style={{ padding: "auto", margin: "auto 1vh auto 3vh" }}
                    >
                      <AccountCircle />
                    </IconButton>

                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      <Link to="/profile" style={{ textDecoration: "none" }}>
                        <MenuItem onClick={handleClose}>Profile</MenuItem>
                      </Link>
                      <MenuItem
                        onClick={() => {
                          base.auth().signOut();
                          handleClose();
                          history.push("/");
                        }}
                      >
                        LogOut
                      </MenuItem>
                    </Menu>
                  </>
                )
              }
            </AuthContext.Consumer>
          </div>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav aria-label="mailbox folders">
        <Hidden mdDown implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "top" : "bottom"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
