import React from "react";
import "./pages.css";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { Grid, Typography, Button, Paper } from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import shanta from "./team/shanta.jpeg";
import prakash from "./team/prakash.jpeg";
import un from "./team/un.jpeg";
import abhi from "./team/abhi.jpeg";
import kaushik from "./team/kaushik.jpeg";
import anvesh from "./team/anvesh.jpeg";
import shiva from "./team/shiva.jpeg";
import vineeth from "./team/vineeth.jpeg";
import chandra from "./team/chandra.jpeg";
import malli from "./team/malli.jpeg";
import durga from "./team/durga.jpeg";
import sachin from "./team/sachin.jpeg";
import manish from "./team/manish.jpeg";
import trivedi from "./team/trivedi.jpeg";
import jaya from "./team/jaya.jpeg";
import TeamU from "../utils/TeamU";
import ganesh from "./team/ganesh.jpeg";
import sonali from "./team/sonali.jpeg";
import sai from "./team/sai.jpeg";
import sreeja from "./team/sreeja.jpeg";
import jeevana from "./team/jeevana.jpeg";
import sandhya from "./team/sandhya.jpeg";
import pranay from "./team/pranay.jpeg";
import shivasai from "./team/shivasai.jpeg";
import pallavi from "./team/pallavi.jpeg";
import koushik from "./team/koushik.jpeg";
import namr from "./team/namr.jpeg";
import ghousiya from "./team/ghousiya.jpeg";
import sumanth from "./team/sumanth.jpeg";
import narayana from "./team/narayan.jpeg";
import barath from "./team/barath.jpeg";
import santosh from "./team/santosh.jpeg";
import sumair from "./team/sumair.jpeg";
import sravya from "./team/sravya.jpeg";
import sriram from "./team/sriram.jpeg";
import yash from "./team/yash.jpeg";
import saiyash from "./team/saiyash.jpeg";
import salim from "./team/salim.jpeg";
import ajitha from "./team/ajitha.jpg";
import nikhil from "./team/nikhil.jpeg";
import onisha from "./team/onisha.jpeg";
import kavya from "./team/kavya.jpeg";
import rahul from "./team/rahul.jpeg";
import Rahul from "./team/Rahul.jpg";
import Paras from "./team/Paras.jpg";
import kartik from "./team/Kartik.jpeg";
import sahithi from "./team/sahithi.jpeg";
import srilaxmi from "./team/inn (1).jpeg";
import rakshith from "./team/inn (2).jpeg"
import vishwas from "./team/inn (3).jpeg"
import thanmai from "./team/inn (4).jpeg"
import anuhya from "./team/inn (5).jpeg"
import anuragh from "./team/inn (6).jpeg"
import shreya1 from "./team/inn (7).jpeg"
import donesh from "./team/inn (8).jpeg"
import hima from "./team/inn (9).jpeg"
import saikiran from "./team/inn (10).jpeg"
import akhila from "./team/inn (11).jpeg"
import akshith from "./team/inn (12).jpeg"
import babu from "./team/inn (13).jpeg"
import saikumar from "./team/inn (14).jpg"
import karan from "./team/karan.jpeg";
import vibaT from "./team/vibaT.png";
import Top from "../Comps/Top";
import bhubesh from "./team/bhubesh.jpeg"
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    height: "100%",
  },
  rootes: {
    flexGrow: 1,
    maxHeight: "100%",
    color: "blue",
    margin: "3vh auto 1vh auto",
  },
}));
export default function Team() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const board = [
    {
      img: shanta,
      title: "Shantha Thoutam",
      job: "PhD, Vice President at T-Hub, Hyderabad",
    },
    {
      img: abhi,
      title: "Abhimanyu Bharadwaj",
      job: "Co-Founder and CEO, Launch Space",
    },
    {
      img: un,
      title: "C. Satyapal Reddy",
      job: "CAO- Vaagdevi College of Engineering",
    },
    {
      img: prakash,
      title: "Dr. K. Prakash",
      job: "Principal- Vaagdevi College of Engineering",
    },
  ];
  const CEO = [
    {
      img: kaushik,
      title: "Dr. M.K. Kaushik",
      job: "PhD from BITS pilani",
    },
  ];
  const mentors = [
    {
      img: chandra,
      title: "Dr.A. Chandramouli",
      job: "PhD from IIT Delhi",
    },
    {
      img: sachin,
      title: "Sachin Pandey",
      job: "CEO, SkillShip",
    },
    {
      img: manish,
      title: "Manish Kumar Gutta",
      job: "CEO, OutShade Digital Media",
    },
    {
      img: trivedi,
      title: "Saurabh Trivedi",
      job: "Director Bhoudik Ventures",
    },
    {
      img: ajitha,
      title: "Ajitha Molakalapalli",
      job: "Founder Aham Learning",
    },
    {
      img: malli,
      title: "Dr. Mallikarjuna",
      job: "Founder, Smiles Foundation",
    },
    {
      img: durga,
      title: "Dr. Durga Hari Kiran",
      job: "PhD from NIT Warangal",
    },
  ];

  const web = [
    {
      img: anvesh,
      title: "Anvesh Reddy",
    },
    {
      img: vineeth,
      title: "Vineeth Pulluru",
    },
    {
      img: shiva,
      title: "Shiva Ravula",
    },
  ];
  const app = [
    {
      img: jaya,
      title: "Jaya Surya",
    },
    {
      img: kartik,
      title: "Karthik",
    },
    {
      img: sahithi,
      title: "Sahithi Sree",
    },
    
  ];
  const content = [
    {
      img: sonali,
      title: "Sonali",
    },
    {
      img: sandhya,
      title: "Sandhya",
    },
    {
      img: pallavi,
      title: "Pallavi",
    },
 
    {
      img: pranay,
      title: "Pranay",
    },
    {
      img: shivasai,
      title: "Shiva Sai",
    },
  ];
  const anime = [
    {
      img: koushik,
      title: "Koushik",
    },
  
   
  
    {
      img: sai,
      title: "Sai Kiran",
    },
  ];
  const iot = [
    {
      img: ganesh,
      title: "Ganesh",
    },
    {
      img: barath,
      title: "Bharadwaja",
    },
    {
      img: santosh,
      title: "Santosh Kumar",
    },

    {
      img: Paras,
      title:"Paras Panda",
    },
  ];
  const ai = [
    {
      img: sravya,
      title: "Sri Sravya",
    },
    {
      img: sriram,
      title: "Sri Ram",
    },
    
  ];
  const opeartions = [
    {
      img: salim,
      title: "ER Salim Jiwani",
      job: "Operations Lead",
    },
    {
      img: nikhil,
      title: "Nikhil",
      job: "Program Manager",
    },
    {
      img: shiva,
      title: "Shiva Ravula",
      job: "Operations Manager",
    },
  ];
  const publics = [
  
    {
      img: kavya,
      title: "Kavya Sri",
    },
    {
      img: rahul,
      title: "Rahul",
    },
    {
      img: sreeja,
      title: "Sreeja",
    },
    {
      img: jeevana,
      title: "Jeevana Sandhya",
    },
   
  ];
  const inno=[
    {img:srilaxmi,title:"Sri Laxmi"},
    {img:rakshith,title:"Rakshith"},
    {img:vishwas,title:"Vishwas"},
    {img:thanmai,title:"Thanmayi Varma"},
    {img:anuhya,title:"Anuhya"},
    {img:anuragh,title:"Anurag"},
    {img:shreya1,title:"Shreya"},
 
    {img:hima,title:"Himadhatri"},
    {img:saikiran,title:"Sai Kiran"},
    {img:akhila,title:"Akhila"},
    {img:akshith,title:"Akshith Raj"},
    {img:babu,title:"Babu Rao"},
    {img:saikumar,title:"Sai Kumar"},


  ];
  const adv=[
    { img: bhubesh,
      title: "Bhubesh Kumar",
      job: "Director, Food & Agriculture, Research and Innovation Circle of Hyderabad.",},
  ]

  return (
    <div className={classes.rootes}>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Top
          imager={vibaT}
          caption={
            "Coming together is a beginning, staying together is progress, and working together is success."
          }
          cap1={"CONVERGE"}
          cap2={"COLLABORATE"}
          cap3={"COMPETE"}
        />
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ padding: "0vh 5vh" }}
        >
          <AppBar
            elevation={0}
            position="static"
            style={{
              backgroundColor: "#FFF",
              marginTop: "7vh",
            }}
            className="appBar"
          >
            <Tabs
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              value={value}
              onChange={handleChange}
              indicatorColor="default"
              variant="fullWidth"
            >
              <Tab
                style={{
                  color: "#FFFFFF",
                  fontWeight: "bolder",
                  margin: "0vh 1.6px",
                  backgroundColor: "#000",
                }}
                label="BOARD"
                {...a11yProps(0)}
              />
              <Tab
                style={{
                  color: "#FFFFFF",
                  fontWeight: "bolder",
                  margin: "0vh 1.6px",
                  padding: "0vh 3vh",
                  backgroundColor: "#000",
                }}
                label="CEO & MENTORS"
                {...a11yProps(1)}
              />
              <Tab
                style={{
                  color: "#FFFFFF",
                  fontWeight: "bolder",
                  margin: "0vh 1.6px",
                  backgroundColor: "#000",
                }}
                label="TEAM"
                {...a11yProps(2)}
              />
            </Tabs>
          </AppBar>
        </Grid>

        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel
            value={value}
            index={0}
            dir={theme.direction}
            style={{ width: "100%", margin: "auto" }}
          >
            <TeamU
              data={board}
              title={"Board"}
              tag={
                "A wise board accumulates rational wisdom, embraces unconventional wisdom and sharpens system wisdom...!"
              }
              var={"h4"}
            />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <TeamU data={CEO} title={"CEO"} var={"h4"} />
            <TeamU
              data={adv}
              title={"advisor"}
              tag={
                "Colleagues are a wonderful thing - But Mentors, that's where the real work gets done...!"
              }
              var={"h4"}
            />
            <TeamU
              data={mentors}
              title={"Mentors"}
              tag={
                "Colleagues are a wonderful thing - But Mentors, that's where the real work gets done...!"
              }
              var={"h4"}
            />
          </TabPanel>

          <TabPanel value={value} index={2} dir={theme.direction}>
            <>
              <TeamU
                data={web}
                title={"Web App Developer's"}
                tag={
                  "What separates design from art is that design is meant to be...Functional...!"
                }
                var={"h4"}
              />
              <TeamU
                data={app}
                title={"Android App Developer's"}
                tag={
                  "The adoption rate of Mobile is twice that of the Internet, three times that of Social Media, and 10 times faster than PCs...!"
                }
                var={"h4"}
              />
              <TeamU
                data={content}
                title={"Content Writer's"}
                tag={
                  "Writing isn't about using big words to impress. It's about using simple words in an impressive way...!"
                }
                var={"h4"}
              />
              <TeamU
                data={anime}
                title={"Animator's"}
                tag={
                  "Animation is not the art of drawings that move but the art of movements that are drawn...!"
                }
                var={"h4"}
              />
              <TeamU
                data={iot}
                title={"IoT Developer's"}
                tag={
                  "By letting the IoT devices into our everyday life, it is not like we entered the zoo, but we released the animals into our world...!"
                }
                var={"h4"}
              />
              <TeamU
                data={ai}
                title={"AI & ML Developer's"}
                tag={
                  "Our intelligence is what makes us human, and AI is an extension of that quality...!"
                }
                var={"h4"}
              />
              <TeamU
                data={opeartions}
                title={"Operations Team"}
                tag={
                  "We are what we repeatedly do. Excellence, then, is not an act, but a habit...!"
                }
                var={"h4"}
              />
              <TeamU
                data={publics}
                title={"Pubilic & Corporate relations Team"}
                tag={
                  "We connect, develop and oversees marketing campaings to promote a sevice...!"
                }
                var={"h4"}
              />
                <TeamU
                data={inno}
                title={"Innovation Fellow's"}
                tag={
                  "We connect, develop and oversees marketing campaings to promote a sevice...!"
                }
                var={"h4"}
              />
            </>
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </div>
  );
}
