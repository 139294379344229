import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import base from "../../../config/FbConfig";
import Dialog from "@material-ui/core/Dialog";
import { Divider, Container } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import "./blog.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import {
  Grid,
  Paper,
  Button,
  Typography,
  Hidden,
  Card,
  CardHeader,
  CardMedia,
  CardActions,
  Avatar,
  CardContent,
  IconButton,
} from "@material-ui/core";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    "& .MuiTextField-root": {
      padding: "3vh",
      minWidth: "81%",
      margin: "3vh auto",
    },
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  avatar: {
    backgroundColor: "#F36600",
  },
  avatars: {
    backgroundColor: "#F36600",
    height: "12vh",
    width: "12vh",
  },
  appBar: {
    position: "sticky",
    top: 0,
    backgroundColor: "#F36600",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  input: {
    display: "none",
  },

  pos: {
    marginBottom: 12,
  },
  avatar: {
    backgroundColor: "#F36600",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BlogCardSub(props) {
  const [bloger, setBloger] = useState([]);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [thisAuthor, setThisAuthor] = React.useState([]);
  const dateObj = new Date(props.time.seconds * 1000);
  const utcString = dateObj.toUTCString();
  const time = utcString.slice(0, 16);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const unsubscribe = base
      .firestore()
      .collection("UserData")
      .doc(props.author)
      .onSnapshot(function (doc) {
        const newData = doc.data();
        setBloger(newData);
        /* setLoading(false); */
      });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    const unsubscribe = base
      .firestore()
      .collection("VibaBlog")
      .doc(props.author)
      .collection("Blogs")
      .onSnapshot((snapshot) => {
        const newData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setThisAuthor(newData);
        /*         setLoading(false);
         */
      });
    return () => unsubscribe();
  }, []);
  //console.log(bloger);

  return (
    <Card className={classes.root}>
      <CardHeader
        style={{ fontFamily: "Montserrat, sans-serif" }}
        avatar={
          <Avatar
            src={bloger.photo}
            aria-label="recipe"
            className={classes.avatar}
          ></Avatar>
        }
        title={bloger.firstName + " " + bloger.lastName}
        subheader={props.time.seconds ? time : null}
      />
      <CardContent>
        <Typography
          variant="h5"
          color="textPrimary"
          component="p"
          style={{ fontFamily: "Montserrat, sans-serif", fontWeight: "700" }}
        >
          {props.title}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          style={{
            fontFamily: "Montserrat, sans-serif",
            paddingTop: "1.8vh",
          }}
        >
          {props.intro.slice(0, 126) + "..."}{" "}
        </Typography>
      </CardContent>
      <CardActions
        disableSpacing
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <IconButton
          onClick={handleClickOpen}
          style={{
            margin: "0.9vh 1.4vh",
            color: "#F36600",
          }}
        >
          <ArrowForwardRoundedIcon />
        </IconButton>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography
                variant="h5"
                className={classes.title}
                style={{ color: "#FFF" }}
              >
                BLOG
              </Typography>
              <IconButton
                autoFocus
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Container component="main" maxWidth="md">
            <div>
              <Grid
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ width: "95%", margin: "5vh auto 0vh auto" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{ width: "63%", margin: "0vh auto 0vh auto" }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${props.img})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      height: "45vh",
                      width: "100%",
                    }}
                  ></div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "3vh 0vh",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      src={bloger.photo}
                      aria-label="recipe"
                      className={classes.avatars}
                    ></Avatar>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "1.2vh 3vh",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="h6"
                          align="left"
                          color="textPrimary"
                          style={{
                            fontWeight: "450",
                            letterSpacing: "1.2px",
                            fontFamily: "Montserrat, sans-serif",
                          }}
                        >
                          {bloger.firstName + " " + bloger.lastName}
                        </Typography>
                      </ThemeProvider>
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle2"
                          align="left"
                          color="textPrimary"
                          style={{
                            fontWeight: "450",
                            letterSpacing: "1.2px",
                            fontFamily: "Montserrat, sans-serif",
                          }}
                        >
                          {props.time.seconds ? time : null}
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <Link
                      to={{
                        pathname: "/moreBlogs",
                        state: {
                          id: props.author,
                          data: thisAuthor,
                          name: `More from ${
                            bloger.firstName + " " + bloger.lastName
                          }`,
                        },
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        style={{
                          color: "#FFF",
                          backgroundColor: "#F36600",
                          margin: "1.2vh 0vh",
                        }}
                      >
                        More from {bloger.firstName + " " + bloger.lastName}
                      </Button>
                    </Link>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    margin: "3vh 0vh",
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      variant="h4"
                      align="left"
                      color="textPrimary"
                      style={{
                        fontWeight: "700",
                        fontFamily: "Montserrat, sans-serif",
                        padding: "5vh 1.2vh",
                        lineHeight: "40px",
                      }}
                    >
                      {props.title}
                    </Typography>
                  </ThemeProvider>
                  <ThemeProvider theme={theme}>
                    <Typography
                      variant="subtitle1"
                      align="left"
                      color="textPrimary"
                      style={{
                        maxWidth: "100%",
                        fontWeight: "450",
                        letterSpacing: "1.2px",
                        fontFamily: "Montserrat, sans-serif",
                        padding: "0vh 1.2vh",
                        lineHeight: "28px",
                      }}
                    >
                      {props.intro}
                    </Typography>
                  </ThemeProvider>
                  {Object.values(props.matter).map((sun, index) => (
                    <div key={index}>
                      <ThemeProvider theme={theme} key={index}>
                        <Typography
                          variant="h5"
                          align="left"
                          color="textPrimary"
                          style={{
                            fontWeight: "700",
                            letterSpacing: "1.2px",
                            fontFamily: "Montserrat, sans-serif",
                            padding: "3vh 1.2vh 2vh 1.2vh",
                          }}
                        >
                          {sun.subHeading}
                        </Typography>
                      </ThemeProvider>
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="subtitle1"
                          align="left"
                          color="textPrimary"
                          style={{
                            maxWidth: "100%",
                            fontWeight: "450",
                            letterSpacing: "1.2px",
                            fontFamily: "Montserrat, sans-serif",
                            padding: "0vh 1.2vh",
                            lineHeight: "28px",
                          }}
                        >
                          {sun.subMatter}
                        </Typography>
                      </ThemeProvider>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </div>
          </Container>
        </Dialog>
      </CardActions>
    </Card>
  );
}
