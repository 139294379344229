import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import "./App.css";
import { Grid, Hidden } from "@material-ui/core";
import Home from "./components/pages/Home.js";
import { AuthContext } from "./config/auth";
import { AuthProvider } from "./config/auth";
import Notfound from "./components/utils/Notfound";
import NavSmallMain from "./components/layout/NavSmallMain";
import TopBar from "./components/layout/TopBar";
import PrivateRoute from "./components/utils/PrivateRoute";
import Login from "./components/utils/Login";
import Signup from "./components/utils/Signup";
import QuesPor from "./components/utils/QuesPor";
import Team from "./components/pages/Team";
import About from "./components/pages/About";
import StartUp from "./components/pages/startUp";
import Events from "./components/pages/events1/Events1";
import Events1 from "./components/pages/detailevent/Detail";
import Blog from "./components/pages/blog/blogMain";
import BlogMore from "./components/pages/blog/BlogMore";
import Profile from "./components/pages/Profile";
import Footer from "./components/pages/footerg/Fotter";
import Detail from "./components/pages/detailevent/Detail";

export default class App extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{ backgroundColor: "#FFFFFF" }}>
        <AuthProvider>
          <Router>
            <div>
              <AuthContext.Consumer>
                {(context) =>
                  context.userId == null ? (
                    <>
                      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid item xs={12}>
                          <TopBar />
                        </Grid>
                        {/* <Hidden mdDown>
                          <Grid item lg={1}>
                            <NavSmallMain />
                          </Grid>
                        </Hidden> */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Switch>
                            <Route exact path="/register" component={Signup} />
                            <Route exact path="/" component={Home} />
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/portal" component={QuesPor} />
                            <Route exact path="/team" component={Team} />
                            <Route exact path="/about" component={About} />
                            <Route exact path="/startups" component={StartUp} />
                            <Route exact path="/events" component={Events} />
                            <Route exact path="/events1" component={Events1} />
                            <Route exact path="/blog" component={Blog} />
                            <Route exact path="/det" component={Detail} />
                            <Route
                              exact
                              path="/moreBlogs"
                              component={BlogMore}
                            />
                          </Switch>
                        </Grid>
                        <Grid item xs={12}>
                          <Footer />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid container xs={12}>
                        <Grid item xs={12}>
                          <TopBar />
                        </Grid>
                        {/* <Hidden mdDown>
                          <Grid item lg={1}>
                            <NavSmallMain />
                          </Grid>
                        </Hidden> */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Switch>
                            <PrivateRoute
                              exact
                              path="/profile"
                              component={Profile}
                            />
                            <Route exact path="/about" component={About} />
                            <Route exact path="/" component={Home} />
                            <Route exact path="/portal" component={QuesPor} />
                            <Route exact path="/team" component={Team} />
                            <Route exact path="/startups" component={StartUp} />
                            <Route exact path="/events" component={Events} />
                            <Route exact path="/blog" component={Blog} />
                            <Route exact path="/events1" component={Events1} />
                            <Route exact path="/det" component={Detail} />
                            <Route
                              exact
                              path="/moreBlogs"
                              component={BlogMore}
                            />
                          </Switch>
                        </Grid>
                        <Grid item xs={12}>
                          <Footer />
                        </Grid>
                      </Grid>
                    </>
                  )
                }
              </AuthContext.Consumer>
            </div>
          </Router>
        </AuthProvider>
      </div>
    );
  }
}
