import React, { useState, useEffect, useContext } from "react";
import hey from "./hey.jpeg";
import { makeStyles } from "@material-ui/core/styles";
import PopUp from "../Comps/PopUp";
import {
  Grid,
  Typography,
  Paper,
  Button,
  TextField,
  Avatar,
  Container,
  Hidden,
} from "@material-ui/core";
import base from "../../config/FbConfig";
import { AuthContext } from "../../config/auth";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import BlogWrite from "./blog/blogWrite";
import BlogCardM from "./blog/blogCardM";

import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);
const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: "#F36600",
    height: "36vh",
    width: "36vh",
    margin: "3vh 0vh 1vh 0vh",
  },

  input: {
    display: "none",
  },
}));

export default function Prafile() {
  const { userId, userName, photo } = useContext(AuthContext);
  const classes = useStyles();
  const [use, setUse] = useState([]);
  const [blow, setBlow] = useState([]);

  useEffect(() => {
    const unsubscribe = base
      .firestore()
      .collection("UserData")
      .doc(userId)
      .onSnapshot(function (doc) {
        const newData = doc.data();
        setUse(newData);
        /* setLoading(false); */
      });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    const unsubscribe = base
      .firestore()
      .collection("VibaBlog")
      .doc(userId)
      .collection("Blogs")
      .onSnapshot((snapshot) => {
        const newData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBlow(newData);
        /*         setLoading(false);
         */
      });
    return () => unsubscribe();
  }, []);

  return (
    <Container component="main" maxWidth="md">
      <div style={{ margin: "5vh 0vh" }}>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                variant="h3"
                align="center"
                style={{
                  fontWeight: "700",
                  letterSpacing: "1.2px",
                  fontFamily: "Montserrat, sans-serif",
                  margin: "3vh 0vh",
                  color: "#F36600",
                  padding: "1.4vh 3vh",
                  textTransform: "uppercase",
                  borderBottom: "0.7px solid black",
                }}
              >
                {use.firstName + " " + use.lastName}
              </Typography>
            </ThemeProvider>
          </Grid>
        </Grid>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={4}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {photo ? (
              <Avatar
                variant="rounded"
                src={photo}
                aria-label="recipe"
                className={classes.avatar}
              />
            ) : (
              <Avatar
                variant="rounded"
                src={hey}
                aria-label="recipe"
                className={classes.avatar}
              />
            )}
            <PopUp />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={8}
            xl={8}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "2vh",
                }}
              >
                <Paper
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    padding: "3vh",
                  }}
                  elevation={7}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      noWrap
                      variant="subtitle1"
                      align="center"
                      style={{
                        fontWeight: "700",
                        letterSpacing: "1.2px",
                        fontFamily: "Montserrat, sans-serif",
                        margin: "1.2vh 0vh",
                        color: "#F36600",
                        paddingBottom: "1.4vh",
                        textTransform: "uppercase",
                        borderBottom: "1.2px solid black",
                      }}
                    >
                      Contact
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      align="center"
                      color="textPrimary"
                      style={{
                        fontWeight: "450",
                        letterSpacing: "1.2px",
                        fontFamily: "Montserrat, sans-serif",
                        margin: "3vh 0vh",
                      }}
                    >
                      {use.mobile}
                    </Typography>
                    <Typography
                      noWrap
                      variant="subtitle1"
                      align="center"
                      color="textPrimary"
                      style={{
                        fontWeight: "450",
                        letterSpacing: "1.2px",
                        fontFamily: "Montserrat, sans-serif",
                        margin: "3vh 0vh",
                      }}
                    >
                      {use.email}
                    </Typography>
                  </ThemeProvider>
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "2vh",
                }}
              >
                <Paper
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    padding: "3vh",
                  }}
                  elevation={7}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      noWrap
                      variant="subtitle1"
                      align="center"
                      style={{
                        fontWeight: "700",
                        letterSpacing: "1.2px",
                        fontFamily: "Montserrat, sans-serif",
                        margin: "1.2vh 0vh",
                        color: "#F36600",
                        paddingBottom: "1.4vh",
                        textTransform: "uppercase",
                        borderBottom: "1.2px solid black",
                      }}
                    >
                      TEAM
                    </Typography>
                    <Typography
                      variant="h5"
                      align="center"
                      color="textPrimary"
                      style={{
                        fontWeight: "450",
                        letterSpacing: "1.2px",
                        fontFamily: "Montserrat, sans-serif",
                        margin: "3vh 0vh",
                      }}
                    >
                      {use.team}
                    </Typography>
                  </ThemeProvider>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "2v 0vh",
            }}
          >
            <Paper
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                padding: "3vh 4.5vh",
              }}
              elevation={7}
            >
              <ThemeProvider theme={theme}>
                <Typography
                  variant="subtitle1"
                  align="center"
                  style={{
                    fontWeight: "700",
                    letterSpacing: "1.2px",
                    fontFamily: "Montserrat, sans-serif",
                    margin: "1.2vh 0vh",
                    color: "#F36600",
                    paddingBottom: "1.4vh",
                    textTransform: "uppercase",
                    borderBottom: "1.2px solid black",
                  }}
                >
                  CURRENT STATUS
                </Typography>
                <Typography
                  variant="subtitle1"
                  align="center"
                  color="textPrimary"
                  style={{
                    fontWeight: "450",
                    letterSpacing: "1.2px",
                    fontFamily: "Montserrat, sans-serif",
                    margin: "3vh 0vh",
                    textTransform: "uppercase",
                  }}
                >
                  Live projects
                  <Typography
                    variant="h4"
                    align="center"
                    color="textPrimary"
                    style={{
                      color: "#F36600",
                      fontWeight: "700",
                      letterSpacing: "1.2px",
                      fontFamily: "Montserrat, sans-serif",
                      margin: "0.7vh 0vh",
                      textTransform: "uppercase",
                    }}
                  >
                    {use.live}
                  </Typography>
                </Typography>
                <Typography
                  variant="subtitle1"
                  align="center"
                  color="textPrimary"
                  style={{
                    fontWeight: "450",
                    letterSpacing: "1.2px",
                    fontFamily: "Montserrat, sans-serif",
                    margin: "3vh 0vh",
                    textTransform: "uppercase",
                  }}
                >
                  Startup working in
                  <Typography
                    noWrap
                    variant="h4"
                    align="center"
                    color="textPrimary"
                    style={{
                      color: "#F36600",
                      fontWeight: "700",
                      letterSpacing: "1.2px",
                      fontFamily: "Montserrat, sans-serif",
                      margin: "2vh 0vh 0vh 0vh",
                      textTransform: "uppercase",
                    }}
                  >
                    {use.startup}
                  </Typography>
                </Typography>
              </ThemeProvider>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "2vh",
            }}
          >
            <Paper
              style={{ maxWidth: "100%", maxHeight: "100%", padding: "3vh" }}
              elevation={7}
            >
              <ThemeProvider theme={theme}>
                <Typography
                  noWrap
                  variant="subtitle1"
                  align="center"
                  style={{
                    fontWeight: "700",
                    letterSpacing: "1.2px",
                    fontFamily: "Montserrat, sans-serif",
                    margin: "1.2vh 0vh",
                    color: "#F36600",
                    paddingBottom: "1.4vh",
                    textTransform: "uppercase",
                    borderBottom: "1.2px solid black",
                  }}
                >
                  My self
                </Typography>
                <Typography
                  variant="subtitle1"
                  align="center"
                  color="textPrimary"
                  style={{
                    letterSpacing: "1.2px",
                    lineHeight: "28px",
                    fontFamily: "Montserrat, sans-serif",
                    margin: "3vh 0vh",
                  }}
                >
                  {use.about}
                </Typography>
              </ThemeProvider>
            </Paper>
          </Grid>
        </Grid>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Paper
              elevation={7}
              style={{
                width: "100%",
                margin: "5vh 0vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "5vh 2vh",
              }}
            >
              <ThemeProvider theme={theme}>
                <Typography
                  variant="h4"
                  align="center"
                  style={{
                    color: "#000",
                    fontWeight: "450",
                    letterSpacing: "1.2px",
                    fontFamily: "Montserrat, sans-serif",
                    margin: "3vh 0vh 1vh 0vh",
                    textTransform: "uppercase",
                    padding: "0vh 2vh",
                  }}
                >
                  Create your own BLOG
                </Typography>
              </ThemeProvider>
              <div
                style={{
                  margin: "0vh 3vh 3vh 3vh",
                  height: "0.3vh",
                  maxWidth: "100%",
                  backgroundColor: "#000",
                }}
              ></div>
              <BlogWrite />
            </Paper>
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ width: "91%", margin: "0vh auto" }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "3vh 0vh",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                variant="h4"
                align="left"
                color="textPrimary"
                style={{
                  fontWeight: "450",
                  letterSpacing: "1.2px",
                  fontFamily: "Montserrat, sans-serif",
                  padding: "0vh 5vh 2vh 0vh",
                  borderBottom: "1.2px solid black",
                }}
              >
                My Blogs
              </Typography>
            </ThemeProvider>
            <Hidden xsDown>
              <Link
                to={{
                  pathname: "/moreBlogs",
                  state: { data: blow, name: "My Blogs" },
                }}
                style={{ textDecoration: "none" }}
              >
                <Button variant="outlined" style={{ color: "#F36600" }}>
                  More
                </Button>
              </Link>
            </Hidden>
          </Grid>

          {blow.slice(0, 2).map((ter) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "3vh",
              }}
            >
              <BlogCardM
                author={userId}
                img={ter.img}
                title={ter.title}
                intro={ter.Introduction}
                time={ter.postedTime}
                matter={ter.matter}
              />
            </Grid>
          ))}
          <Hidden smUp>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Link
                to={{
                  pathname: "/moreBlogs",
                  state: { data: blow, name: "My Blogs" },
                }}
                style={{ textDecoration: "none" }}
              >
                <Button variant="outlined" style={{ color: "#F36600" }}>
                  More
                </Button>
              </Link>
            </div>
          </Hidden>
        </Grid>
      </div>
    </Container>
  );
}
