import React, { useCallback, useState, Children, useContext } from "react";
import { Grid, Paper, Button, Typography, Hidden } from "@material-ui/core";
import "./blog.css";
import BlogCardM from "./blogCardM";
import BlogWrite from "./blogWrite";
import base from "../../../config/FbConfig";
import { AuthContext } from "../../../config/auth";

import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

export default function BlogMore(props) {
  const namer = props.location.state;
  const { userId, userName } = useContext(AuthContext);

  return (
    <div>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ThemeProvider theme={theme}>
            <Typography
              variant="h2"
              align="left"
              style={{
                color: "#F36600",
                fontWeight: "700",
                letterSpacing: "1.2px",
                fontFamily: "Montserrat, sans-serif",
                margin: "1.6vh 3vh",
              }}
            >
              VIBA's<span className="blog">BLOG</span>
            </Typography>
          </ThemeProvider>
          <div
            style={{
              margin: "0vh 3vh 3vh 3vh",
              height: "0.3vh",
              maxWidth: "100%",
              backgroundColor: "#000",
            }}
          ></div>
        </Grid>
      </Grid>

      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ width: "91%", margin: "0vh auto" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "3vh 0vh",
          }}
        >
          <ThemeProvider theme={theme}>
            <Typography
              variant="h4"
              align="left"
              color="textPrimary"
              style={{
                fontWeight: "450",
                letterSpacing: "1.2px",
                fontFamily: "Montserrat, sans-serif",
                padding: "0vh 5vh 2vh 0vh",
                borderBottom: "1.2px solid black",
              }}
            >
              {namer.name}
            </Typography>
          </ThemeProvider>
        </Grid>

        {namer.data.map((ter) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={3}
            xl={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "3vh",
            }}
          >
            <BlogCardM
              author={namer.id ? namer.id : ter.id}
              img={ter.img}
              title={ter.title}
              intro={ter.Introduction}
              time={ter.postedTime}
              matter={ter.matter}
            />
          </Grid>
        ))}
      </Grid>
      {userId ? (
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Paper
              elevation={7}
              style={{
                width: "81%",
                margin: "5vh auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "5vh 0vh",
              }}
            >
              <ThemeProvider theme={theme}>
                <Typography
                  variant="h4"
                  align="center"
                  style={{
                    color: "#000",
                    fontWeight: "450",
                    letterSpacing: "1.2px",
                    fontFamily: "Montserrat, sans-serif",
                    margin: "3vh 0vh 1vh 0vh",
                    textTransform: "uppercase",
                  }}
                >
                  Create your own BLOG
                </Typography>
              </ThemeProvider>
              <div
                style={{
                  margin: "0vh 3vh 3vh 3vh",
                  height: "0.3vh",
                  maxWidth: "100%",
                  backgroundColor: "#000",
                }}
              ></div>
              <BlogWrite />
            </Paper>
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
}
