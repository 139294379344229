import firebase from "firebase";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAbdc4R8qPKX_hCmDx7CVeKpfFq8oYrEqk",
  authDomain: "viba-2020.firebaseapp.com",
  databaseURL: "https://viba-2020.firebaseio.com",
  projectId: "viba-2020",
  storageBucket: "viba-2020.appspot.com",
  messagingSenderId: "605908745836",
  appId: "1:605908745836:web:25790f14a5a5bbc582e249",
  measurementId: "G-YQ0D6DT5S7"
};
// Initialize Firebase
const base = firebase.initializeApp(firebaseConfig);

export default base;
