import React from "react";
import Particles from "react-particles-js";
import { Grid, Typography, Paper, Button, Hidden } from "@material-ui/core";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import Viba from "./86.png";
import "./HomePage.css";
import CounterM from "./counter/Counter";
import img4 from "./pages/img/O.PNG";
import aham from "./pages/img/aham.svg";
import img5 from "./pages/img/e.png";
import img6 from "./pages/img/SF.png";
import img7 from "./pages/img/SS.png";
import img8 from "./pages/img/DRNME.png";
import img9 from "./pages/img/BV.png";
import img10 from "./pages/img/sp.png";
import img11 from "./pages/img/bt.png";
import kala from "./img/kala.jpeg";
import biz from "./img/biz.jpeg";
import hack from "./img/hack.jpg";
import pu from "./img/pu.jpeg";
import Bout from "./shiva/Bout";
import v from "./pages/Events/ls.jpeg"
import ph from "./pages/Events/s1.jpeg"
import di from "./pages/Events/2-0.jpg"
import ric from "./richh.jpg"
import MailRoundedIcon from "@material-ui/icons/MailRounded";
import PhoneRoundedIcon from "@material-ui/icons/PhoneRounded";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EventsSlide from "./pages/EventsSlide";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

export default function HomePage() {
  const part = [img4, img5, img11, img6, img7, aham, img8, img9, img10,v,ph,di];
  const rich=[ric];
  const eve = [kala, pu, biz, hack];

  return (
    <div style={{ backgroundColor: "#FFFFFF", height: "100%" }}>
      <Hidden mdUp>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ padding: "5vh 2.1vh" }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              position: "relative",
              backgroundColor: "#F36600",
              height: "75vh",
              maxWidth: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderTopLeftRadius: "32px",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                variant="h3"
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "700",
                  marginBottom: "1.5vh",
                }}
                className="vibaHomeR"
              >
                VIBA
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <Typography
                variant="h6"
                align="inherit"
                style={{
                  color: "#FFF",
                  lineheight: "1.3px",
                  fontWeight: "450",
                  letterSpacing: "0.6px",
                  fontFamily: "Montserrat, sans-serif",
                  zIndex: "9",
                  margin: "3vh 0vh",
                }}
                className="centerText"
              >
                WE FOSTER STARTUP'S AND INNOVATION ECOSYSTEMS
              </Typography>
            </ThemeProvider>
            <Link to="/about" style={{ textDecoration: "none" }}>
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                style={{ color: "#FFF", margin: "3vh 1.2vh", zIndex: "9" }}
              >
                explore
              </Button>
            </Link>

            <ArrowDownwardIcon
              style={{
                position: "absolute",
                fontSize: "7vh",
                backgroundColor: "#FFFF",
                color: "#F36600",
                borderRadius: "50%",
                paddingTop: "2vh",
                bottom: "5.9vh",
              }}
            />
            <Typography
              variant="subtitle2"
              style={{
                color: "#FFF",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "700",
                marginBottom: "1.5vh",
                marginTop: "3px",
                position: "absolute",
                bottom: "0.5vh",
              }}
            >
              scroll down
            </Typography>
          </Grid>
          <div className="linker">
            <Link
              to={{
                pathname: "https://www.facebook.com/vaagdevi.colleges.18",
              }}
              target="_blank"
            >
              <FacebookIcon
                style={{
                  color: "#F36600",
                  margin: "1.6vh",
                  fontSize: "4.1vh",
                  transform: "rotate(-27deg)",
                }}
              />
            </Link>
            <Link
              to={{
                pathname: "https://instagram.com/___viba__?igshid=mu7qora4ybqb",
              }}
              target="_blank"
            >
              <InstagramIcon
                style={{
                  color: "#F36600",
                  margin: "1.6vh",
                  fontSize: "4.1vh",
                  transform: "rotate(-27deg)",
                }}
              />
            </Link>
            <Link
              to={{
                pathname:
                  "https://www.youtube.com/channel/UCRqcdVRlH7nLdD3VsQQ9wWQ",
              }}
              target="_blank"
            >
              <YouTubeIcon
                style={{
                  color: "#F36600",
                  margin: "1.6vh",
                  fontSize: "4.1vh",
                  transform: "rotate(-27deg)",
                }}
              />
            </Link>
            <Link
              to={{
                pathname:
                  "https://www.linkedin.com/company/viba-vaagdevi-incubation-and-business-accelerator",
              }}
              target="_blank"
            >
              <LinkedInIcon
                style={{
                  color: "#F36600",
                  margin: "1.6vh",
                  fontSize: "4.1vh",
                  transform: "rotate(-27deg)",
                }}
              />
            </Link>
          </div>
        </Grid>
      </Hidden>
      <Hidden smDown>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ backgroundColor: "#FFF" }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="topCon">
            <Hidden mdDown>
              <li>Vaagdevi incubation and Business Accelerator</li>
            </Hidden>
            <ul>
              <li>
                <PhoneRoundedIcon
                  style={{ fontSize: "3vh", color: "#f36600" }}
                />
                <span>9885545514</span>
              </li>
              <li>
                <MailRoundedIcon
                  style={{ fontSize: "3vh", color: "#f36600" }}
                />
                <span>vibafoundation@gmail.com</span>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smDown>
        <Particles
          style={{ height: "100%", position: "relative" }}
          params={{
            particles: {
              number: {
                value: 160,
                density: {
                  enable: true,
                  value: 900,
                },
              },
              size: {
                value: 3,
                random: true,
                anim: {
                  speed: 4,
                  size_min: 0.3,
                },
              },
              color: {
                value: "#F36600",
              },
              line_linked: {
                enable: false,
              },
              move: {
                random: true,
                speed: 1,
                direction: "top",
                out_mode: "out",
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: "bubble",
                },
                onclick: {
                  enable: true,
                  mode: "repulse",
                },
              },
              modes: {
                bubble: {
                  distance: 250,
                  duration: 2,
                  size: 9,
                  opacity: 0.3,
                },
                repulse: {
                  distance: 70,
                  duration: 4,
                },
              },
            },
          }}
        />
      </Hidden>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <Hidden smDown>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5} className="titleHome">
            <ThemeProvider theme={theme}>
              <Typography
                variant="h5"
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "700",
                  marginBottom: "3vh",
                }}
                className="vibaHome"
              >
                VIBA
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <Typography
                variant="h4"
                color="textSecondary"
                align="inherit"
                style={{
                  lineheight: "1.3px",
                  fontWeight: "450",
                  letterSpacing: "0.6px",
                  fontFamily: "Montserrat, sans-serif",
                  zIndex: "9",
                }}
                className="centerText"
              >
                WE FOSTER STARTUP'S AND INNOVATION ECOSYSTEMS
              </Typography>
            </ThemeProvider>
            <Link to="/about" style={{ textDecoration: "none" }}>
              <Button
                variant="outlined"
                color="inherit"
                style={{ color: "#F36600", margin: "3vh 1.2vh", zIndex: "9" }}
              >
                explore
              </Button>
            </Link>
            <div className="links">
              <Link
                to={{
                  pathname: "https://www.facebook.com/vaagdevi.colleges.18",
                }}
                target="_blank"
              >
                <FacebookIcon
                  style={{
                    color: "#F36600",
                    margin: "1.6vh",
                    fontSize: "4.1vh",
                  }}
                />
              </Link>
              <Link
                to={{
                  pathname:
                    "https://instagram.com/___viba__?igshid=mu7qora4ybqb",
                }}
                target="_blank"
              >
                <InstagramIcon
                  style={{
                    color: "#F36600",
                    margin: "1.6vh",
                    fontSize: "4.1vh",
                  }}
                />
              </Link>
              <Link
                to={{
                  pathname:
                    "https://www.youtube.com/channel/UCRqcdVRlH7nLdD3VsQQ9wWQ",
                }}
                target="_blank"
              >
                <YouTubeIcon
                  style={{
                    color: "#F36600",
                    margin: "1.6vh",
                    fontSize: "4.1vh",
                  }}
                />
              </Link>
              <Link
                to={{
                  pathname:
                    "https://www.linkedin.com/company/viba-vaagdevi-incubation-and-business-accelerator",
                }}
                target="_blank"
              >
                <LinkedInIcon
                  style={{
                    color: "#F36600",
                    margin: "1.6vh",
                    fontSize: "4.1vh",
                  }}
                />
              </Link>
            </div>
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            style={{
              backgroundImage: `url(${Viba})`,
            }}
            className="imageHome"
          ></Grid>
        </Hidden>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <CounterM />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Bout />
      </Grid>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ backgroundColor: "#FBFBFD" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          className="LeftT"
         style={{maxHeight:"80vh"}}
        >
          <ThemeProvider theme={theme}>
            <Typography
              variant="h5"
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
                color: "#F36600",
              }}
            >
             ECOSYSTEM 
              <Typography
                variant="h4"
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "bold",
                  color: "#000",
                }}
              >
               PARTNER
              </Typography>
            </Typography>
          </ThemeProvider>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={9}
          lg={9}
          xl={9}
          style={{
          
            maxHeight: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12} style={{
          
          maxHeight: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
            {rich.map((text, index) => (
              <Grid item xs={12} sm={6} md={4} lg={7} xl={3}  style={{
          
                maxHeight: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
             
                  <img src={rich[index]} style={{objectFit:"cover",width:"90%",height:"100%"}}/>
                
              </Grid>
            ))}
          </Grid>
        </Grid>
       
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          className="LeftT"
          style={{ maxHeigth: "100%" }}
        >
          <ThemeProvider theme={theme}>
            <Typography
              variant="h5"
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
                color: "#F36600",
              }}
            >
              PARTNERS
              <Typography
                variant="h4"
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "bold",
                  color: "#000",
                }}
              >
                WITH US
              </Typography>
            </Typography>
          </ThemeProvider>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={9}
          lg={9}
          xl={9}
          style={{
            maxHeight: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
            {part.map((text, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <Paper
                  elevation={5}
                  style={{
                    padding: "3vh",
                    height: "18vh",
                    width: "63%",
                    margin: "3vh auto",
                  }}
                >
                  <img src={part[index]} height="100%" width="100%" />
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            backgroundColor: "#FFF",
            maxHeight: "100%",
            padding: "5vh 0vh",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              margin: "2vh 0vh 5vh 0vh",
              maxHeigth: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                variant="h5"
                align="center"
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "bold",
                  color: "#F36600",
                }}
              >
                EVENTS
                <Typography
                  variant="h4"
                  align="center"
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bold",
                    color: "#000",
                  }}
                >
                  POST STATISTICS
                </Typography>
              </Typography>
            </ThemeProvider>
            <Link to="/events" style={{ textDecoration: "none" }}>
              <Button
                variant="outlined"
                style={{ color: "#F36600", margin: "1.2vh 0vh" }}
              >
                Explore
              </Button>
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ padding: "1vh 0vh 3vh 0vh" }}
          >
            <EventsSlide />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
